import { clientEndPoints } from '../../../../utils/clientEndPoints';
import { endpoints } from '../../../../utils/endPoints';
import instance from '../../../../api';
import API from '../../../../api';
import { GridAlignment, GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { CampusOption, ICertificateStudent, ConfirmationCertificateProgramSelected } from './types';
import { Input } from '@silinfo/front-end-template';
import OperationsCellRenderer from './OperationsCellRenderer';
import { actualStatusOptions, semesterStatusOptions } from '../../../MasterData/General/Students/Form/options';
import { IForm } from '../../../../utils/Interfaces/interfaces';
import { Dispatch, SetStateAction } from 'react';
import { GridSelectionModel } from '@mui/x-data-grid-pro';
import { MemoizedTableCell } from '../MemoizedTableCell';
import { CheckboxMemo } from '../CheckboxMemo';

export const breadcrumbs = {
    administration: 'Adminisztráció',
    [clientEndPoints.admin_certificates]: 'Igazolások',
    [clientEndPoints.admin_certificates_confirmation_training_program]: 'Igazolás a teljesítésről (képzési program)',
};

export const header = (tenant: string | null) => {
    return {
        title: 'Igazolás a teljesítésről (képzési program)',
        breadcrumbs: {},
        project: tenant || 'TAR',
    };
};

export const service = (trainingProgram?: string | number) => ({
    filter: (filter: Record<string, unknown>) => {
        return instance.get(
            endpoints.administration.certificates.confirmation.programList + `?trainingProgram=${trainingProgram}`,
            {
                params: filter,
            },
        );
    },
    initiateExport: (filter: IForm) =>
        API.post(
            endpoints.administration.certificates.confirmation.export.initiate,
            { format: 'docx' },
            { params: filter },
        ),
    checkExport: (id: number) =>
        API.get(endpoints.administration.certificates.confirmation.export.check.replace('{id}', id.toString()), {
            params: { trainingProgram: '1' },
        }),
    downloadExport: (id: number) =>
        API.get(endpoints.administration.certificates.confirmation.export.download.replace('{id}', id.toString()), {
            responseType: 'arraybuffer',
            params: { trainingProgram: '1' },
        }),
});

const autoload = true as const;

export const columns = (archive?: boolean): GridColDef<ICertificateStudent>[] => {
    const baseColumns: GridColDef<ICertificateStudent>[] = [
        {
            field: 'email',
            headerName: 'Email',
            sortable: true,
        },
        {
            field: 'lastName',
            headerName: 'Vezetéknév',
            sortable: true,
        },
        {
            field: 'firstName',
            headerName: 'Keresztnév',
            sortable: true,
        },
        {
            field: 'campus',
            headerName: 'Campus',
            sortable: true,
        },
        {
            field: 'collegeYear',
            headerName: 'Évfolyam',
            sortable: true,
        },
        {
            field: 'semesterStatus',
            headerName: 'Szemeszter státusz',
            sortable: true,
        },
        {
            field: 'currentMembershipStatus',
            headerName: 'Aktuális státusz',
            sortable: true,
        },
    ].map((column) => ({
        flex: 1,
        minWidth: 150,
        headerAlign: 'center',
        align: 'center',
        ...column,
    }));

    if (!archive) {
        baseColumns.push({
            field: 'operations',
            headerName: 'Műveletek',
            renderCell: (params: GridRenderCellParams) => <OperationsCellRenderer {...params} />,
            align: 'center' as GridAlignment,
            headerAlign: 'center' as GridAlignment,
            flex: 1,
            minWidth: 110,
            sortable: false,
        });
    }

    return baseColumns;
};

export const filterFields = (): Input[] => {
    return [
        {
            name: 'email',
            label: 'Email',
            type: 'text',
        },
        {
            name: 'lastName',
            label: 'Vezetéknév',
            type: 'text',
        },
        {
            name: 'firstName',
            label: 'Keresztnév',
            type: 'text',
        },
        {
            name: 'campus',
            label: 'Campus',
            type: 'backendSelect',
            url: endpoints.administration.certificates.confirmation.campusOptions,
            multiple: true,
            justValue: true,
            props: {
                renderOption: (props: React.HTMLAttributes<HTMLLIElement>, option: CampusOption) => (
                    <li {...props} style={{ color: option.active ? 'black' : '#606060' }}>
                        {option.label}
                    </li>
                ),
            },
        },
        {
            name: 'collegeYear',
            label: 'Évfolyam',
            type: 'backendSelect',
            url: endpoints.administration.certificates.confirmation.collegeYearOptions,
            multiple: true,
            justValue: true,
        },
        {
            name: 'currentMembershipStatus',
            label: 'Aktuális státusz',
            type: 'multiselect',
            options: actualStatusOptions,
        },
        {
            name: 'semesterStatus',
            label: 'Szemeszter státusz',
            type: 'multiselect',
            options: semesterStatusOptions,
        },
    ];
};

export const tableProps = (
    setSelected: Dispatch<SetStateAction<ConfirmationCertificateProgramSelected>>,
    trainingProgram?: string,
    archive?: boolean,
    tenant?: string,
) => ({
    autoload,
    header: header(tenant || 'TAR'),
    service: service(trainingProgram),
    columns: columns(archive),
    defaultSort: { lastName: 'asc' as const },
    filter: {
        fields: filterFields(),
    },
    refresh: trainingProgram,
    responseKey: 'data',
    tableProps: {
        disableSelectionOnClick: true,
        initialState: {
            pinnedColumns: {
                right: ['operations'],
            },
        },
        components: {
            Cell: MemoizedTableCell,
            BaseCheckbox: CheckboxMemo,
        },
        onSelectionModelChange: (selectionModel: GridSelectionModel) => {
            setSelected((prev) => ({ ...prev, selected: selectionModel, trainingProgram: trainingProgram }));
        },
        hideFooterSelectedRowCount: false,
        checkboxSelection: true,
        style: {
            width: '100%',
        },
        autoHeight: true,
    },
});
