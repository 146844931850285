import DateRange from '@mui/icons-material/DateRange';
import Grid from '@mui/material/Grid';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { FormikListPage, Link, TableButton, TableDeleteButton, TableEditButton } from '@silinfo/front-end-template';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MCCLoading from '../../../../components/MCCLoading';
import administrationFeesService, {
    IAdministrationFeeType,
} from '../../../../services/masterData/finances/administrationFees/administrationFees';
import { RootState } from '../../../../store';
import { create } from '../../../../store/notification';
import { TooltipRenderer } from '../../../../utils/AppConstElements';
import { IForm } from '../../../../utils/Interfaces/interfaces';
import { clientEndPoints } from '../../../../utils/clientEndPoints';
import api from '../../../../api';
import { endpoints } from '../../../../utils/endPoints';
import { useTenant } from '../../../../components/TenantContext';
import { GridColDef, GridColumns } from '@mui/x-data-grid';

export default function AdministrationFees() {
    const { user } = useSelector((state: RootState) => state.auth);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [currency, setCurrency] = useState<string>('');
    const tenant = useTenant();

    const header = {
        project: tenant || 'TAR',
        title: 'Adminisztrációs díjak',
        breadcrumbs: {
            masterData: 'Törzsadatok',
            [clientEndPoints.md_finance]: 'Pénzügy',
            [clientEndPoints.md_finance_administration_fees_list]: 'Adminisztrációs díjak',
        },
    };

    useEffect(() => {
        api.get(endpoints.currentTenantAll)
            .then((response) => {
                setCurrency(response.data.currencyTrans);
            })
            .catch((error) => {
                console.error('Error fetching current tenant currency:', error);
            });
    }, []);

    const columns = (): GridColumns =>
        [
            {
                headerName: 'Díjkategória',
                field: 'category',
                renderCell: (params: GridRenderCellParams<string, IAdministrationFeeType>) => {
                    let category = 'ismeretlen';
                    switch (params.row.category) {
                        case 'payment':
                            category = 'Kifizetés';
                            break;
                        case 'inpayment':
                            category = 'Befizetés';
                            break;
                    }
                    return TooltipRenderer(category);
                },
            },
            {
                headerName: 'Megnevezés',
                field: 'type',
                renderCell: (params: GridRenderCellParams<string, IAdministrationFeeType>) =>
                    TooltipRenderer(params.row.type),
            },
            {
                headerName: 'Azonosító',
                field: 'typeShort',
                renderCell: (params: GridRenderCellParams<string, IAdministrationFeeType>) =>
                    TooltipRenderer(params.row.typeShort),
            },
            {
                headerName: 'Szemeszter',
                field: 'semester',
                renderCell: (params: GridRenderCellParams<string, IAdministrationFeeType>) => {
                    if (params.row.typeShort === 'ODA') {
                        // Ösztöndíjhoz nem rendelünk szemesztert
                        return <></>;
                    }

                    if (params.row.valuesCount > 0) {
                        for (const elem of params.row.financeAdministrationFeeVals) {
                            if (elem.semester?.active) {
                                return TooltipRenderer(
                                    elem.semester.name +
                                        ' - ' +
                                        elem.semester.processName +
                                        ' (' +
                                        moment(elem.semester.startDate).toDate().toLocaleDateString('hu') +
                                        ' - ' +
                                        moment(elem.semester.endDate).toDate().toLocaleDateString('hu') +
                                        ')',
                                );
                            }
                        }

                        if (!params.row.financeAdministrationFeeVals[0].semester) {
                            return <></>;
                        }

                        return TooltipRenderer(
                            params.row.financeAdministrationFeeVals[0].semester.name +
                                ' - ' +
                                params.row.financeAdministrationFeeVals[0].semester.processName +
                                ' (' +
                                moment(params.row.financeAdministrationFeeVals[0].semester.startDate)
                                    .toDate()
                                    .toLocaleDateString('hu') +
                                ' - ' +
                                moment(params.row.financeAdministrationFeeVals[0].semester.endDate)
                                    .toDate()
                                    .toLocaleDateString('hu') +
                                ')',
                        );
                    }
                },
                sortable: false,
            },
            {
                headerName: 'Összeg',
                field: 'amount',
                renderCell: (params: GridRenderCellParams<string, IAdministrationFeeType>) => {
                    if (params.row.valuesCount > 0) {
                        for (const elem of params.row.financeAdministrationFeeVals) {
                            if (elem.semester?.active) {
                                return TooltipRenderer(elem.amount + ' ' + currency);
                            }
                        }

                        if (!params.row.financeAdministrationFeeVals[0].amount) {
                            return <></>;
                        }

                        return TooltipRenderer(params.row.financeAdministrationFeeVals[0].amount + ' ' + currency);
                    }
                },
                sortable: false,
            },
            {
                headerName: 'Műveletek',
                field: 'operations',
                renderCell: (params: GridRenderCellParams<string, IAdministrationFeeType>) => {
                    if (params.row.typeShort === 'ODA') {
                        // Ösztöndíjhoz nem rendelünk szemesztert, nincsenek műveletek
                        return <></>;
                    }

                    return (
                        <Grid container spacing={0.5}>
                            {!params.row.flag && !user.archive && (
                                <Grid item>
                                    <Link to={params.row.id + '/edit'}>
                                        <TableEditButton />
                                    </Link>
                                </Grid>
                            )}

                            {!user.archive && (
                                <Grid item>
                                    {params.row.valuesCount === 0 && !params.row.flag && (
                                        <TableDeleteButton
                                            deleteAction={() => {
                                                setLoading(true);
                                                administrationFeesService
                                                    .delete(params.row.id)
                                                    .then(() => {
                                                        dispatch(
                                                            create({ type: 'success', message: 'Sikeres törlés' }),
                                                        );
                                                    })
                                                    .catch(() =>
                                                        dispatch(
                                                            create({
                                                                type: 'error',
                                                                message: 'Sikertelen törlés',
                                                            }),
                                                        ),
                                                    )
                                                    .finally(() => setLoading(false));
                                            }}
                                            confirmationElement={params.row.type}
                                        />
                                    )}
                                </Grid>
                            )}
                            <Grid item>
                                {!params.row.scholarship && (
                                    <Link
                                        to={clientEndPoints.md_finance_administration_fees_semesters_list.replace(
                                            ':feeId',
                                            params.row.id.toString(),
                                        )}
                                    >
                                        <TableButton tooltip={'Szemeszterek listázása'} style={{ marginRight: '5px' }}>
                                            <DateRange />
                                        </TableButton>
                                    </Link>
                                )}
                            </Grid>
                        </Grid>
                    );
                },
                sortable: false,
            },
        ].map((column: GridColDef) => ({
            headerAlign: 'center',
            align: 'left',
            flex: 1,
            ...column,
        }));

    if (loading) return <MCCLoading />;

    return (
        <FormikListPage
            newLabel={!user.archive ? 'Új adminisztrációs díj hozzáadása' : ''}
            autoload
            header={header}
            service={{ filter: (form: IForm) => administrationFeesService.feesByTypes(form) }}
            columns={columns()}
            defaultSort={{ flag: 'desc' }}
            responseKey={'data'}
            tableProps={{
                style: {
                    width: '100%',
                },
                autoHeight: true,
            }}
        />
    );
}
