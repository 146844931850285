import Grid from '@mui/material/Grid';
import { Page } from '@silinfo/front-end-template';
import { AxiosResponse } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import ExportButton, { ExportContext } from '../../../../components/Buttons/ExportButton';
import MCCLoading from '../../../../components/MCCLoading';
import studentsService from '../../../../services/masterData/students';
import { RootState } from '../../../../store';
import { createObjectFromParams, fetchData, initialInfo } from '../../../../utils/AppConst';
import { IInfo } from '../../../../utils/Interfaces/IInfo';
import { IForm, Student } from '../../../../utils/Interfaces/interfaces';
import { clientEndPoints } from '../../../../utils/clientEndPoints';
import useAccessCheck from '../../../../utils/useAccessCheck';
import { baseFields } from './Form/fields';
import { ViewProps } from './Form/types';
import View from './View';
import { columns } from './columns';
import { Options, fetchData as fetchOptions, initOptions } from './utils';
import { useTenant } from '../../../../components/TenantContext';
import Box from '@mui/material/Box';
import useColumnOrder from '../../../../utils/useColumnOrder';

const initFilter: IForm = {
    terminating: ['0'],
};

export default function Students() {
    const { archive } = useSelector((state: RootState) => state.auth.user);
    const [rawSearchParams, setSearchParams] = useSearchParams();
    const searchParams = createObjectFromParams([...rawSearchParams.entries()], baseFields());
    const [filter, setFilter] = useState<IForm>(
        [...rawSearchParams.entries()].length > 0 ? { ...searchParams } : initFilter,
    );
    const [options, setOptions] = useState<Options>(initOptions);
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<Student[]>([]);
    const [isFirstRender, setIsFirstRender] = useState(true);
    const [tableLoading, setTableLoading] = useState<boolean>(false);
    const [info, setInfo] = useState<IInfo<Student>>(initialInfo({ name: 'asc' }));
    const hasAccessToEdit = useAccessCheck(['md_general_students_edit_base', 'md_general_students_edit_detailed']);
    const hasAccessToAdd = useAccessCheck(['md_general_students_create']);
    const hasCustomButtonAccess = useAccessCheck(['md_general_students_list_read_only_show']);
    const tenant = useTenant();

    const fetchDataBase = useCallback(
        (isDefault = false) => {
            const paramsObject = isDefault ? initFilter : filter;

            const params = new URLSearchParams();
            Object.entries(paramsObject).forEach(([key, value]) => {
                if (Array.isArray(value)) {
                    value.forEach((v) => params.append(key, v));
                } else {
                    params.set(key, String(value));
                }
            });

            setSearchParams(params);

            fetchData(
                paramsObject,
                studentsService,
                setTableLoading,
                setData,
                isDefault ? initialInfo({ name: 'asc' }) : info,
                setInfo,
            );
        },
        [filter, info, setSearchParams],
    );

    useEffect(() => {
        if (isFirstRender) {
            setIsFirstRender(false);
            sessionStorage.setItem('students', 'studentVal');
            fetchOptions(setLoading, setOptions);
            fetchData(filter, studentsService, setTableLoading, setData, initialInfo({ name: 'asc' }), setInfo);
        }
    }, [filter, isFirstRender]);

    const exportContextDetailed: ExportContext = {
        initiateExport: studentsService.initiateExport as (
            form: { format: string },
            filter: IForm,
        ) => Promise<AxiosResponse>,
        checkExport: studentsService.checkExport,
        downloadExport: studentsService.downloadExport,
        info,
        filter,
    };

    const exportContextFurtherStudies: ExportContext = {
        initiateExport: studentsService.initiateExportFurtherStudies as (
            form: { format: string },
            filter: IForm,
        ) => Promise<AxiosResponse>,
        checkExport: studentsService.checkExportFurtherStudies,
        downloadExport: studentsService.downloadExportFurtherStudies,
        info,
        filter,
    };

    const header = {
        project: tenant || 'TAR',
        title: 'Hallgatók',
        breadcrumbs: {
            'masterData': 'Törzsadatok',
            [clientEndPoints.md_general]: 'Általános',
            students: 'Hallgatók',
        },
    };

    const [columnDefs] = useState(columns(fetchDataBase, !(!archive && hasAccessToEdit)));

    const { handleColumnOrderChange, resetColumnOrder, getOrderedColumns } = useColumnOrder(columnDefs);

    const tableData: Omit<ViewProps<Student>, 'changeForm'> = {
        filter: {
            onSubmit: fetchDataBase,
            form: filter,
            setForm: () => {
                setFilter((prev) => ({ ...prev }));
                fetchDataBase(true);
                resetColumnOrder();
            },
            fields: baseFields(options),
        },
        table: {
            initialState: {
                pinnedColumns: {
                    right: ['operations'],
                },
            },
            columns: getOrderedColumns(),
            rows: data,
            loading: tableLoading,
            setInfo: (name: string, data: string) =>
                setInfo((prevState) => ({
                    ...prevState,
                    [name]: data,
                })),
            searchfunc: (i) => {
                setSearchParams(filter as Record<string, string>);
                return fetchData(filter, studentsService, setTableLoading, setData, i, setInfo);
            },
            info,
            newButton:
                archive || !hasAccessToAdd
                    ? undefined
                    : {
                          target: clientEndPoints.md_general_students_create,
                          text: 'Hallgató hozzáadása',
                      },
            server: true,
            columnBuffer: columnDefs.length,
            customButtons:
                !archive && hasCustomButtonAccess ? (
                    <Grid item>
                        <Box display="inline-flex" gap={2}>
                            <ExportButton exportContext={exportContextDetailed} />
                            <ExportButton
                                title={'További tanulmányok exportálása'}
                                exportContext={exportContextFurtherStudies}
                            />
                        </Box>
                    </Grid>
                ) : (
                    <></>
                ),
            onColumnOrderChange: handleColumnOrderChange,
            style: {
                width: '100%',
            },
            autoHeight: true,
        },
    };

    const changeForm = () => (e: { target: { name: string; value: unknown } }) => {
        setFilter((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    if (loading) return <MCCLoading />;

    return (
        <Page header={header}>
            <View changeForm={changeForm()} {...tableData} />
        </Page>
    );
}
