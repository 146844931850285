import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { FormikListPage } from '@silinfo/front-end-template';
import axios, { AxiosResponse } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import MCCLoading from '../../../../../../../../components/MCCLoading';
import itemsService from '../../../../../../../../services/masterData/items';
import roomsService from '../../../../../../../../services/masterData/rooms';
import { create } from '../../../../../../../../store/notification';
import { deleteUnnecessaryFields, paginatorInfoBuild } from '../../../../../../../../utils/AppConst';
import { IInfo } from '../../../../../../../../utils/Interfaces/IInfo';
import { initialRoom } from '../../../../../../../../utils/Interfaces/initialObjects';
import { IForm, Room } from '../../../../../../../../utils/Interfaces/interfaces';
import { BtnCellRenderer } from './BtnCellRenderer';
import { columns, fields, initialInfo, useHeader } from './utils';
import useColumnOrder from '../../../../../../../../utils/useColumnOrder';

export default function Items() {
    const [data, setData] = useState<[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [tableLoading, setTableLoading] = useState<boolean>(false);
    const [room, setRoom] = useState<Room>(initialRoom);
    const [type, setType] = useState<string>('room');
    const dispatch = useDispatch();
    const params = useParams();
    const { campusId, buildingId, floorId, roomId } = params as {
        campusId: string;
        buildingId: string;
        floorId: string;
        roomId: string;
    };
    const header = useHeader(type);
    const [info, setInfo] = useState(initialInfo);
    const [firstLoad, setFirstLoad] = useState<boolean>(true);

    const [columnDefs] = useState(columns);
    const { handleColumnOrderChange, resetColumnOrder, getOrderedColumns } = useColumnOrder(columnDefs);

    const fetchData = useCallback(
        (form: IInfo<IForm>) => {
            setTableLoading(true);
            axios
                .all([
                    itemsService
                        .filter({ ...form.filter, ...paginatorInfoBuild(form) }, campusId, buildingId, floorId, roomId)
                        .then((d: AxiosResponse) => {
                            const totalItems = d.data['hydra:totalItems'];
                            setData(d.data['hydra:member']);
                            setInfo((prev) => ({
                                ...prev,
                                metadata: {
                                    'allCount': totalItems,
                                    'filteredCount': totalItems,
                                    'lastPage': totalItems ? Math.ceil(totalItems / info.perpage) : 1,
                                    'page': info.page,
                                    'perpage': info.perpage,
                                    'rowCount': totalItems,
                                },
                            }));
                        }),
                    roomsService.get(roomId).then((response) => {
                        setType(response.data.type);
                        setRoom(response.data);
                    }),
                ])
                .catch(() =>
                    dispatch(
                        create({
                            type: 'error',
                            message: 'Hiba a betöltés során!',
                        }),
                    ),
                )
                .finally(() => {
                    setTableLoading(false);
                    setLoading(false);
                });
        },
        [buildingId, campusId, dispatch, floorId, info.page, info.perpage, roomId],
    );

    const setValueByKey = (key: string, value: string) => {
        setInfo((prev) => ({ ...prev, [key]: value }));
    };

    useEffect(() => {
        if (firstLoad) {
            setFirstLoad(false);
            fetchData(initialInfo);
        }
    }, [fetchData, firstLoad]);

    const loadTable = () => {
        setTableLoading(true);
        itemsService
            .filter({}, campusId, buildingId, floorId, roomId)
            .then((response: AxiosResponse) => setData(response.data['hydra:member'] || []))
            .finally(() => setTableLoading(false));
    };

    const onSubmit = (form: IForm) => {
        setTableLoading(true);
        const newForm = deleteUnnecessaryFields(form, ['campus', 'building', 'floor']);
        setInfo((prevState) => ({
            ...prevState,
            filter: newForm,
        }));
        const filter = { ...newForm, ...paginatorInfoBuild(info) };

        return itemsService
            .filter(filter, campusId, buildingId, floorId, roomId)
            .then((response: AxiosResponse) => setData(response.data['hydra:member'] || []))
            .finally(() => setTableLoading(false));
    };

    if (loading) {
        return <MCCLoading />;
    }

    return (
        <FormikListPage
            key={JSON.stringify(getOrderedColumns())}
            header={header}
            filter={{
                initialValues: {
                    campusName: room.floor.building.campus.name,
                    buildingName: room.floor.building.name,
                    floorName: room.floor.name,
                    roomName: room.name,
                },
                onSubmit,
                onReset: resetColumnOrder,
                fields: fields(type),
            }}
            table={{
                columnDefs: getOrderedColumns(),
                onColumnOrderChange: handleColumnOrderChange,
                rowData: data,
                loading: tableLoading,
                searchfunc: fetchData,
                info: info,
                server: true,
                initialState: {
                    sorting: {
                        sortModel: [{ field: 'name', sort: 'asc' }],
                    },
                },
                setInfo: setValueByKey,
                frameworkComponents: {
                    btnCellRenderer: (params: GridRenderCellParams) => (
                        <BtnCellRenderer loadTable={loadTable} {...params} />
                    ),
                },
                style: {
                    width: '100%',
                },
                autoHeight: true,
            }}
        />
    );
}
