import DialogBasic from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import Form from '../../../../../../../components/Form/Form';
import trainingProgramRequirementCourseTypesService from '../../../../../../../services/masterData/trainingProgramRequirementCourseTypes';
import { IForm } from '../../../../../../../utils/Interfaces/interfaces';
import { initialCourseTypeRequirementForm } from './types';
import UpdateCourseTypeRequirementForm from './UpdateCourseTypeRequirementForm';
import { SemesterOption } from '../Requirements';

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
}));

export interface ICourseTypeEditForm {
    id: number;
    courseType: number;
    eventType: string;
    type: string;
    numberOfCoursesToBeCompleted: number;
    numberOfCoursesToBeCompletedPerSemester: number;
    minAmountToBeCompletedFromOtherTrainingPrograms: number;
    visibleOnCompletionPage: number;
    worthPoint: number;
    includedInTheScholarship: number;
}

export default function EditButton({
    opener,
    id,
    programId,
    campusId,
    semester,
    setTableLoading,
    courseTypeId,
}: {
    opener: JSX.Element;
    id?: string;
    programId?: string;
    campusId?: string;
    semester?: SemesterOption;
    setTableLoading: (val: boolean) => void;
    courseTypeId?: string;
}) {
    const [open, setOpen] = useState(false);

    const classes = useStyles();
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            {React.cloneElement(opener, {
                onClick: () => {
                    handleOpen();
                },
            })}
            <DialogBasic open={open} onClose={handleClose} fullWidth>
                <DialogTitle className={classes.title}>Szerkesztés</DialogTitle>
                <div>
                    <DialogContent>
                        <Form
                            hideButtons
                            fields={[]}
                            onSubmit={(form: IForm) =>
                                trainingProgramRequirementCourseTypesService
                                    .update(id, programId, campusId, semester?.value, form)
                                    .then(() => {
                                        setTableLoading(true);
                                        setTableLoading(false);
                                        handleClose();
                                    })
                            }
                            initialValues={initialCourseTypeRequirementForm}
                            submitTransformData={(form) => {
                                form.courseType = +(courseTypeId ?? '');
                                return form;
                            }}
                            readonly={semester?.closed}
                        >
                            {(props) => (
                                <UpdateCourseTypeRequirementForm
                                    submitBtnTitle={'Mentés'}
                                    handleClose={handleClose}
                                    courseTypeId={courseTypeId}
                                    semester={semester}
                                    {...props}
                                />
                            )}
                        </Form>
                    </DialogContent>
                </div>
            </DialogBasic>
        </>
    );
}
