import { ICourseEventLecture } from '../../../../interface/ICourseEventLecture';
import courseTypesService from '../../../../services/masterData/courses/courseTypes';
import { Option } from '../../../../utils/AppConst';
import { IAddress } from '../Events/utils';
import { TBaseData } from './types';

export const toStrip = [
    '“',
    "'",
    '„',
    '`',
    '~',
    '!',
    '@',
    '#',
    '$',
    '%',
    '^',
    '&',
    '*',
    '(',
    ')',
    ']',
    '[',
    '+',
    '=',
    '{',
    '}',
    '|',
    ':',
    ';',
    '"',
    '<',
    '>',
    ',',
    '.',
    '?',
    '-',
    '_',
    ' ',
];

// Kurzusnév kezdetéből vágjuk le ezeket a stringeket kurzuskód generálásnál
export const toStripStart = ['Az ', 'A ', 'Egy '];

export function getAvaibleMaximumPoints(
    propValues: TBaseData,
    eventLectures: ICourseEventLecture[],
    eventAll: ICourseEventLecture[],
) {
    let sumPoints = 0.0;
    let add = 0;

    if (propValues.fixCoursePoint == '1') {
        add = parseFloat(propValues.fixCoursePointVal);
        if (!isNaN(add)) {
            sumPoints += add;
        }
    }

    if (propValues.customCoursePoint == '1') {
        add = parseFloat(propValues.customCoursePointValMax);
        if (!isNaN(add)) {
            sumPoints += add;
        }
    }

    if (propValues.fixedCoursePointPerEvent == '1') {
        add = eventLectures.length * +(propValues.fixedCoursePointPerEventValPoint || 0);
        if (!isNaN(add)) {
            sumPoints += add;
        }
    }

    sumPoints += getCustomCoursePointPerEventPoints(propValues, eventAll);

    return sumPoints;
}

export function getAchievedMinimumPoints(
    propValues: TBaseData,
    eventLectures: ICourseEventLecture[],
    eventAll: ICourseEventLecture[],
) {
    let sumPoints = 0.0;
    let add = 0;

    if (propValues.fixCoursePoint == '1') {
        add = parseFloat(propValues.fixCoursePointVal);
        if (!isNaN(add)) {
            sumPoints += add;
        }
    }

    if (propValues.customCoursePoint == '1') {
        add = parseFloat(propValues.customCoursePointValMin);
        if (!isNaN(add)) {
            sumPoints += add;
        }
    }

    if (propValues.fixedCoursePointPerEvent == '1' && propValues.fixedCoursePointPerEventValMin) {
        const lecturesCount = eventLectures.length;
        add =
            (lecturesCount < parseFloat(propValues.fixedCoursePointPerEventValMin)
                ? lecturesCount
                : parseFloat(propValues.fixedCoursePointPerEventValMin)) *
            +(propValues.fixedCoursePointPerEventValPoint || 0);
        if (!isNaN(add)) {
            sumPoints += add;
        }
    }

    sumPoints += getCustomCoursePointPerEventPoints(propValues, eventAll, true);

    return sumPoints;
}

export function getCustomCoursePointPerEventPoints(
    propValues: TBaseData,
    eventAll: ICourseEventLecture[],
    isMinPoint?: boolean,
): number {
    let sum = 0;
    if (propValues.customCoursePointPerEvent == '1' && eventAll) {
        for (let i = 0; i < eventAll.length; i++) {
            sum += isMinPoint === true ? eventAll[i].pointMin ?? 0 : eventAll[i].pointMax ?? 0;
        }
    }
    return sum;
}

interface HeadCount {
    minHeadCount: string;
    maxHeadCount: string;
    maxAbsence: string;
}

export const baseInitialValues = {
    isTenant: false,
    courseName: '',
    selectedCourseName: '',
    courseNameEnglish: '',
    semester: '',
    courseManager: '' as string | Option,
    instructors: [] as Option[],
    guestInstructors: [] as string[],
    achievementType: '',
    campus: '',
    courseCodePost: '',
    building: '',
    floor: '',
    room: '',
    organiser: [],
    otherOrganiser: [] as string[],
    organiserCampus: '',
    courseCategory: '',
    courseType: '',
    headCounts: {} as { [key: string]: HeadCount },
    courseCode: '',
    selectedCourseCode: '',
    external: false,
    addresses: [] as IAddress[],
    externalLocationName: {
        description: '',
    },
    trainingProgram: [] as Option[],
    isLockUsersField: false,
    redirect: 'edit',
    fixCoursePoint: '',
    fixCoursePointVal: '',
    customCoursePoint: '',
    customCoursePointValMin: '',
    customCoursePointValMax: '',
    fixedCoursePointPerEvent: '',
    fixedCoursePointPerEventValMin: '',
    fixedCoursePointPerEventValPoint: '',
    customCoursePointPerEvent: '0' as '0' | '1',
    readonly: false,
    waitlist: false,
    hasValuedStudent: false,
    questionnaire: false,
    maxPointAvailable: '',
    headCount: '',
    enrollmentStartDate: '',
    enrollmentEndDate: '',
    eventCount: 0,
    closed: false,
    scoringType: 'point' as 'point' | 'copper',
    questionnaireAnonymous: true,
    questionnaireTemplate: {
        value: '',
        label: '',
    },
    questionnaireTiming: '0',
    questionnaireActivationStart: new Date().toISOString().split('T')[0],
    questionnaireDeadline: '0',
    questionnaireDeadlineDate: new Date().toISOString().split('T')[0],
    questionnaireActive: false,
    presentsPerLocation: false,
    communicationPerLocation: false,
    cateringPerLocation: false,
    itNeedPerLocation: false,
    roomLayoutPerLocation: false,
    tenants: [] as string[],
};

export const courseTypeServiceWithNewFilter = {
    filter: () => {
        const params = {
            'active[]': 1,
            itemsPerPage: 100,
        };

        return courseTypesService.filter(params);
    },
};

export const courseTypeServiceWithNewEmptyFilter = { filter: () => courseTypesService.filter({ itemsPerPage: 100 }) };

export const createUserOptions = (users: Option[]) =>
    users.map((user) => ({
        value: user.value.toString(),
        label: user.label,
    }));

export const getScoringTypeText = (scoringType: string): string => (scoringType === 'point' ? 'pont' : 'garas');
