import Grid from '@mui/material/Grid';
// eslint-disable-next-line regex/invalid-warn
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { AutoloadTable, Loading, NewButton, TableDeleteButton } from '@silinfo/front-end-template';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import trainingProgramRequirementCourseReferenceCodeService from '../../../../../../../services/masterData/trainingProgramRequirementCourseReferenceCode';
import { create } from '../../../../../../../store/notification';
import AddRequiredCourseCodeButton from './AddRequiredCourseCodeButton';
import { tableColumns } from './fields';
import { ICourseReferenceCodeRequirement } from './types';
import { SemesterOption } from '../Requirements';

export default function RequiredCourses(params: { readonly?: boolean; semester?: SemesterOption; refresh: boolean }) {
    const { id, programId, campusId } = useParams();
    const [tableLoading, setTableLoading] = useState(false);
    const dispatch = useDispatch();

    const OperationCellRenderer = ({ row }: GridRenderCellParams<ICourseReferenceCodeRequirement>) => {
        return (
            <Grid container spacing={1} flexDirection={'row'}>
                <Grid item xs={6}>
                    {!params.readonly && !params.semester?.closed && (
                        <TableDeleteButton
                            tooltip={'Törlés'}
                            deleteAction={() => {
                                setTableLoading(true);
                                trainingProgramRequirementCourseReferenceCodeService
                                    .delete(id, programId, campusId, row.id?.toString(), params.semester?.value)
                                    .then(() => dispatch(create({ type: 'success', message: 'Sikeres törlés' })))
                                    .catch(() => dispatch(create({ type: 'error', message: 'Sikertelen törlés' })))
                                    .finally(() => setTableLoading(false));
                            }}
                            confirmationElement={row.courseCode}
                        />
                    )}
                </Grid>
            </Grid>
        );
    };

    return (
        <Grid container spacing={2}>
            {!params.readonly && !params.semester?.closed && (
                <Grid item container xs={12} justifyContent="flex-end">
                    <Grid item>
                        <Grid item xs={12}>
                            <AddRequiredCourseCodeButton
                                title="Hozzáadás"
                                submitBtnTitle="Hozzáadás"
                                setTableLoading={setTableLoading}
                                opener={<NewButton>Hozzáadás</NewButton>}
                                id={id}
                                programId={programId}
                                campusId={campusId}
                                semesterId={params.semester?.value}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )}
            <Grid item xs={12}>
                {tableLoading ? (
                    <Loading noHeight />
                ) : (
                    <AutoloadTable
                        columns={tableColumns(OperationCellRenderer)}
                        filter={(form) =>
                            trainingProgramRequirementCourseReferenceCodeService.list(
                                id,
                                programId,
                                campusId,
                                params.semester?.value ?? 0,
                                form,
                            )
                        }
                        responseKey="data"
                        refresh={params.refresh}
                    />
                )}
            </Grid>
        </Grid>
    );
}
