import Campuses from '../../../pages/MasterData/General/Campuses/Campuses';
import CampusForm from '../../../pages/MasterData/General/Campuses/CampusForm';
import { campusesBuildings } from './CampusesBuildings';

export const campuses = {
    path: 'campuses',
    children: [
        {
            path: '',
            element: <Campuses />,
            name: 'md_general_campuses_list',
        },
        {
            path: ':campusId/edit',
            element: <CampusForm readOnly />,
            name: 'md_general_campuses_edit',
        },
        campusesBuildings,
    ],
};
