import Grid from '@mui/material/Grid';
import { FormikListPage, Header, Link, NewButton } from '@silinfo/front-end-template';
import { useCallback, useEffect, useMemo, useState } from 'react';
import EndpointProvider from '../../../components/EndpointProvider';
import SemesterSelector from '../../../components/SemesterSelector';
import { SemesterContextProvider } from '../../../components/SemesterSelector/SemesterContextProvider';
import { RefreshContextProvider } from '../../../studentPages/SelfAssessment/RefreshContextProvider';
import { Option } from '../../../utils/AppConst';
import { clientEndPoints } from '../../../utils/clientEndPoints';
import { endpoints } from '../../../utils/endPoints';
import OperationsCellRenderer from './OperationsCellRenderer';
import { filterFields } from './fields';
import { breadcrumbs, columns, header, service } from './utils';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { useTenant } from '../../../components/TenantContext';

const AddGroupButton = ({ semester }: { semester: string }) => {
    return (
        <Grid item>
            <EndpointProvider endpoints={['scholarship_group_management_create']}>
                <Link to={clientEndPoints.scholarship_group_management_create.replace(':semesterId', semester)}>
                    <NewButton>Új csoport létrehozása</NewButton>
                </Link>
            </EndpointProvider>
        </Grid>
    );
};

interface GroupManagementProps {
    title?: string;
    Operations?: typeof OperationsCellRenderer;
    disableAdd?: boolean;
}

export default function GroupManagement({ title, Operations, disableAdd }: GroupManagementProps) {
    const { user } = useSelector((state: RootState) => state.auth);
    const semesterState = useState<Option | null>(null);
    const [semester] = semesterState;
    const [refresh, setRefresh] = useState(false);
    const tenant = useTenant();
    const headerWithTenant = header(tenant || 'TAR');
    const toggleRefresh = useCallback(() => setRefresh((prev) => !prev), []);

    useEffect(() => {
        toggleRefresh();
    }, [semester, toggleRefresh]);

    const headerProps = useMemo(
        () => ({
            ...headerWithTenant,
            title: title || headerWithTenant.title,
            breadcrumbs: {
                ...breadcrumbs,
                groupManagement: title || breadcrumbs.groupManagement,
            },
        }),
        [title, headerWithTenant],
    );

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Header {...headerProps} />
            </Grid>
            <Grid item xs={12}>
                <SemesterSelector
                    defaultActive
                    semesterState={semesterState}
                    url={endpoints.scholarships.admin.semesters}
                />
            </Grid>
            <Grid item xs={12}>
                <SemesterContextProvider semester={semester?.value || ''}>
                    <RefreshContextProvider refresh={toggleRefresh}>
                        <FormikListPage
                            filter={{
                                fields: filterFields(semester?.value || ''),
                            }}
                            header={{ ...headerProps, breadcrumbs: {} }}
                            responseKey="data"
                            autoload
                            service={service(semester?.value || '')}
                            columns={columns(Operations || OperationsCellRenderer, user.archive)}
                            tableProps={{
                                style: {
                                    width: '100%',
                                },
                                autoHeight: true,
                            }}
                            refresh={refresh}
                            {...(disableAdd
                                ? {}
                                : {
                                      customButtons: (
                                          <>{!user.archive && <AddGroupButton semester={semester?.value || ''} />}</>
                                      ),
                                  })}
                        />
                    </RefreshContextProvider>
                </SemesterContextProvider>
            </Grid>
        </Grid>
    );
}
