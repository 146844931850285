import { endpoints } from '../../utils/endPoints';
import API from '../../api';
import { IForm } from '../../utils/Interfaces/interfaces';

const courseEnrollmentService = {
    delete: (courseId: string, id: string | number, courseTenant: string | null, userTenant: number | null) => {
        const endpoint = endpoints.courseManagement.enrollments.delete
            .replace('{courseId}', courseId)
            .replace('{enrollmentId}', id.toString());

        if (courseTenant) {
            return API.delete(endpoint + '?courseTenant=' + courseTenant + '&userTenant=' + userTenant);
        } else {
            return API.delete(endpoint + '?userTenant=' + userTenant);
        }
    },
    enroll: (courseId: number | string, courseTenant: string | null, form: IForm) => {
        const endpoint = endpoints.courseManagement.enrollments.enroll.replace('{id}', courseId.toString());

        if (courseTenant) {
            return API.post(endpoint + '?courseTenant=' + courseTenant, form);
        } else {
            return API.post(endpoint, form);
        }
    },
};

export default courseEnrollmentService;
