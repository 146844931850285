import Grid from '@mui/material/Grid';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { AutoloadTable } from '@silinfo/front-end-template';
import { NewButton } from '@silinfo/front-end-template/lib/esm/components/Button';
import { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import EndpointProvider from '../../../../components/EndpointProvider';
import courseDocumentService from '../../../../services/courseManagement/courseDocument';
import { TooltipRenderer } from '../../../../utils/AppConstElements';
import AddEditDocumentButton from './AddEditDocumentButton';
import { tableColumns } from './fields';
import useColumnOrder from '../../../../utils/useColumnOrder';

export function FileNameCellRenderer(params: GridRenderCellParams) {
    return TooltipRenderer(params.row.fileName);
}

export function UpdatedAtCellRenderer(params: GridRenderCellParams) {
    return TooltipRenderer(params.row.updateDate?.split('+')[0]?.replace('T', ' '));
}

export default function Documents(props: { closed: boolean; readOnly?: boolean }) {
    const { courseId } = useParams() as { courseId: string };
    const [searchParams] = useSearchParams();
    const courseTenant = searchParams.get('courseTenant');
    const id: number = parseInt(courseId);
    const [tableLoading, setTableLoading] = useState(false);
    const { closed, readOnly } = props;

    const [columnDefs] = useState(tableColumns(setTableLoading, courseTenant, readOnly));
    const { handleColumnOrderChange, getOrderedColumns } = useColumnOrder(columnDefs, 'course_list_documents');

    return (
        <Grid container spacing={2}>
            <Grid item container xs={12} justifyContent="flex-end">
                {!closed && !readOnly && (
                    <EndpointProvider endpoints={['course_manamagement_course_add_document']}>
                        <Grid item>
                            <AddEditDocumentButton
                                title="Dokumentum hozzáadása"
                                submitBtnTitle="Hozzáadás"
                                setTableLoading={setTableLoading}
                                opener={<NewButton>Dokumentum hozzáadása</NewButton>}
                                wasSyllabus={false}
                                courseTenant={courseTenant}
                            />
                        </Grid>
                    </EndpointProvider>
                )}
            </Grid>
            <Grid item xs={12}>
                <AutoloadTable
                    filter={(form) => courseDocumentService.filter(id, courseTenant, form)}
                    columns={getOrderedColumns()}
                    responseKey="data"
                    title="Dokumentumok"
                    refresh={tableLoading}
                    onColumnOrderChange={handleColumnOrderChange}
                />
            </Grid>
        </Grid>
    );
}
