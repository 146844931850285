import Grid from '@mui/material/Grid';
import { FormikProps } from 'formik';
import * as React from 'react';
import { useState } from 'react';
import Autocomplete from '../../../../components/Form/Autocomplete';
import { FieldGenerator } from '../../../../components/Form/FieldGenerator';
import courseListService from '../../../../services/courseManagement/courseList';
import { Option } from '../../../../utils/AppConst';
import { TBaseData, TrefreshCodeVersion } from './types';
import { toStrip, toStripStart } from './utils';

export function CourseNameField(
    props: {
        id: string | undefined;
        courseTypeCodeVersion: string | null;
        refreshCodeVersion: TrefreshCodeVersion;
        courseTypeOptions: (Option<string> & {
            extraVal?: string | undefined;
            extraVal2?: string | undefined;
        })[];
        setCourseTypeShortName: React.Dispatch<React.SetStateAction<string>>;
    } & FormikProps<TBaseData>,
) {
    const [ccOptions, setCcOptions] = useState<(Option & { courseReferenceCode: string })[]>([]);

    if (props.id || props.courseTypeCodeVersion) {
        return (
            FieldGenerator({
                name: 'courseName',
                label: 'Kurzus megnevezése',
                format: { xs: 12, sm: 6, md: 5 },
                props: {
                    disabled: props.values.readonly,
                },
                ...props,
            }) || <></>
        );
    }

    return (
        <Grid item xs={12} sm={6} md={5}>
            <Autocomplete<Option, false, true>
                getter={(term) => {
                    const res = courseListService.courseNameSearch(term, props.values.courseType?.toString());
                    res.then((res) => {
                        setCcOptions(res.data || []);
                    });
                    return res;
                }}
                textFieldProps={{ label: 'Kurzus megnevezése' }}
                errorMessage={props.errors?.courseName as string}
                autocompleteProps={{
                    renderOption: (props: object, option: string | Option) => {
                        // Ez azért szükséges, mert a select elemeiben részletesebb infót írunk ki
                        return <div {...props}>{typeof option === 'string' ? option : option.label}</div>;
                    },
                    getOptionLabel: (option) => (typeof option === 'string' ? option : option.value) || '',
                    disabled: props.values?.readonly,
                    blurOnSelect: false, // Amennyiben kiválaszt valamit azonnal kimegyünk az inputból, !!!! TALÁN MÁR JÓ FIXME: ezzel meghívva az onBlur-t is kéne, de nem hívja meg sajnos. TALÁN MÁR JÓ!!!!!
                    freeSolo: true, // Elfogadunk olyan stringet is, amit nem kiválaszt hanem csak beír
                    value: props.values?.courseName,
                    onChange: (_, v) => {
                        if (v && typeof v !== 'string') {
                            const find: (Option & { courseReferenceCode: string }) | undefined = ccOptions.find(
                                (opt) => {
                                    return opt.value === v.value;
                                },
                            );

                            props.setFieldValue('courseCode', find?.courseReferenceCode?.substring(4));
                            props.setFieldValue('selectedCourseCode', find?.courseReferenceCode?.substring(4));
                            props.setFieldValue('courseName', v?.value);
                            props.setFieldValue('selectedCourseName', v?.value);
                            const courseType = props.courseTypeOptions.find(
                                (x) => x.extraVal2 === find?.courseReferenceCode.split('-')[0],
                            );
                            if (courseType) {
                                props.setFieldValue('courseType', courseType.value);
                                props.setCourseTypeShortName(courseType.extraVal2 || '');
                            }
                            props.refreshCodeVersion(undefined, undefined, find?.courseReferenceCode?.substring(4));
                        }
                    },
                    onBlur: (e: React.FocusEvent<HTMLInputElement>) => {
                        props.setFieldValue('courseName', e.target.value);
                        if (!props.values.selectedCourseCode) {
                            // Mivel nem volt kiválasztva semmi, úgy újrageneráljuk a kurzuskódot
                            const courseCode = generateCourseCodeFromName(e.target.value);
                            props.setFieldValue('courseCode', courseCode);
                            props.refreshCodeVersion(undefined, true, courseCode);
                        }
                        /*
                        if (e.target.value !== props.values?.selectedCourseName) {
                            if (e.target.value !== props.values.courseName) {
                                // Módosított a szövegen, tehát a kurzuskódot újra kell generálni
                                const courseCode = generateCourseCodeFromName(e.target.value);
                                props.setFieldValue('courseCode', courseCode);
                                props.refreshCodeVersion(undefined, true, courseCode);
                            }
                        }
                         */
                    },
                }}
                createOptions={(elements) => elements}
            />
        </Grid>
    );
}

/**
 * Kurzuskód generálás kurzusnév alapján alapján
 * @param courseName
 * @param randomGeneration
 */
export function generateCourseCodeFromName(courseName: string, randomGeneration?: boolean): string {
    let code = courseName;

    // Levágjuk a fixen rossz kezdetet
    toStripStart.forEach((x: string) => {
        if (courseName.substring(0, x.length).toLowerCase() === x.toLowerCase()) {
            // Ezzel kezdődik, tehát levágjuk
            code = code.substring(x.length);
        }
    });

    // Cseréljük az értelmetlen karaktereket
    toStrip.forEach((x: string) => {
        code = code.replaceAll(x, '');
    });

    if (randomGeneration === true) {
        // Ez esetben random generálunk az első betű után
        const alphabet = 'abcdefghijklmnopqrstuvwxyz';
        const randomCharacter1 = alphabet[Math.floor(Math.random() * alphabet.length)];
        const randomCharacter2 = alphabet[Math.floor(Math.random() * alphabet.length)];
        return (code.substring(0, 1) + randomCharacter1 + randomCharacter2)?.toUpperCase();
    } else {
        return code.substring(0, 3)?.toUpperCase();
    }
}
