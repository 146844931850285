import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { DataGridPro, GridColDef, GridRowModel, GridValueGetterParams } from '@mui/x-data-grid-pro';
import { translation } from '@silinfo/front-end-template';
import { defaultTheme, financeFinancialApprovalStatuses, formatNumberToThousands } from '../../utils/AppConst';
import { Paper } from '../Requirements/Paper';
import FinanceInfoComponent from './FinanceInfoComponent';
import useMediaQuery from '@mui/material/useMediaQuery';
import { theme } from '../../utils/theme';
import { GridRenderCellParams } from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import { useState } from 'react';
import PaymentGuideDialog from './PaymentGuideDialog';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import { ThemeProvider } from '@mui/material/styles';

export type TFinanceStatus = keyof typeof financeFinancialApprovalStatuses;

export interface IFinanceRow {
    id: number;
    identifier: string;
    name: string;
    amount: number;
    date: string;
    status: TFinanceStatus;
    comment: string;
    invoiceFile?: string;
    updatedFileName?: string;
}

interface FinanceTableProps {
    title: string;
    rows: GridRowModel[];
    columns?: GridColDef[];
    loading?: boolean;
    currency?: string;
}

const FinanceTable = (props: FinanceTableProps) => {
    const [open, setOpen] = useState(false);
    const handleOpenDialog = () => setOpen(true);

    const { rows, title, loading, currency } = props;

    const matches = useMediaQuery(theme.breakpoints.up('md'));
    const matchesLg = useMediaQuery(theme.breakpoints.up('lg'));

    const columns: GridColDef[] =
        props.columns?.map((col) => {
            if (!matches) {
                return {
                    ...col,
                    minWidth: 60,
                    maxWidth: 300,
                    renderCell: (params: GridRenderCellParams) => (
                        <Tooltip title={params.value || '-'}>
                            <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{params.value}</div>
                        </Tooltip>
                    ),
                };
            }
            return { ...col };
        }) ||
        [
            {
                headerName: 'Azonosító',
                field: 'identifier',
            },
            {
                headerName: 'Megnevezés',
                field: 'name',
                minWidth: 300,
            },
            {
                headerName: 'Összeg',
                field: 'amount',
                valueGetter: (params: GridValueGetterParams) =>
                    `${formatNumberToThousands(params.row.amount || 0)} ${currency}`,
                minWidth: 100,
            },
            {
                headerName: 'Létrehozás dátuma',
                field: 'date',
            },
            {
                headerName: 'Státusz',
                field: 'status',
                valueGetter: (rowData: GridValueGetterParams<TFinanceStatus>) =>
                    rowData.value ? financeFinancialApprovalStatuses[rowData.value] : '-',
            },
            {
                headerName: '',
                renderCell: FinanceInfoComponent,
                field: 'info',
                minWidth: 100,
                flex: 0.5,
            },
        ].map((col) => {
            if (!matches) {
                if (col.headerName === '') {
                    return {
                        headerName: '',
                        renderCell: (params: GridRenderCellParams) => (
                            <Tooltip title={params.value || '-'}>
                                <FinanceInfoComponent {...params} />
                            </Tooltip>
                        ),
                        field: 'info',
                        minWidth: 80,
                        maxWidth: 300,
                        flex: 0.5,
                    };
                }
                return {
                    ...col,
                    minWidth: 60,
                    maxWidth: 300,
                    renderCell: (params: GridRenderCellParams) => (
                        <Tooltip title={params.value || '-'}>
                            <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{params.value}</div>
                        </Tooltip>
                    ),
                };
            }
            return { flex: 1, minWidth: 150, ...col };
        });

    return (
        <Grid item xs={12}>
            <Paper>
                <Grid container spacing={3} xs={12}>
                    <Grid
                        item
                        xs={12}
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        style={{ position: 'relative' }}
                        sx={{ marginY: title === 'Befizetésre váró tételek' ? '' : '1rem' }}
                    >
                        <Typography
                            variant="h6"
                            style={{
                                position: 'absolute',
                                left: '50%',
                                transform: 'translateX(-50%)',
                            }}
                        >
                            {title}
                        </Typography>
                        {title === 'Befizetésre váró tételek' && (
                            <Box
                                display="flex"
                                alignItems="center"
                                onClick={handleOpenDialog}
                                sx={{
                                    cursor: 'pointer',
                                    marginLeft: 'auto',
                                }}
                            >
                                {matchesLg ? (
                                    <ThemeProvider theme={defaultTheme}>
                                        <Alert severity="info">Befizetéshez segédlet</Alert>
                                    </ThemeProvider>
                                ) : (
                                    <IconButton>
                                        <InfoIcon sx={{ color: '#0288d1' }} />
                                    </IconButton>
                                )}
                            </Box>
                        )}
                    </Grid>
                    <Grid item xs={12} className="noTransition">
                        <DataGridPro
                            rows={rows}
                            columns={columns}
                            density="compact"
                            localeText={translation}
                            hideFooter
                            loading={loading}
                            style={{
                                height: rows.length > 0 ? 256 : 128,
                                width: '100%',
                            }}
                            autoHeight={false}
                        />
                    </Grid>
                </Grid>
            </Paper>

            {title === 'Befizetésre váró tételek' && (
                <PaymentGuideDialog open={open} handleClose={() => setOpen(false)} />
            )}
        </Grid>
    );
};

export default FinanceTable;
