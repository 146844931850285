import Home from '@mui/icons-material/Home';
import { Link, TableButton, TableEditButton } from '@silinfo/front-end-template';
import EndpointProvider from '../../../../../components/EndpointProvider';
import { clientEndPoints } from '../../../../../utils/clientEndPoints';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';

export default function BtnCellRenderer(params: GridRenderCellParams & { loadTable: () => void; readonly?: boolean }) {
    return (
        <>
            <EndpointProvider endpoints={['md_general_campuses_edit']}>
                <Link to={clientEndPoints.md_general_campuses_edit.replace(':campusId', params.row.id.toString())}>
                    <TableEditButton tooltip={'Szerkesztés'} sx={{ marginRight: '5px' }} />
                </Link>
            </EndpointProvider>
            <EndpointProvider endpoints={['md_general_campuses_buildings_list']}>
                <Link
                    to={clientEndPoints.md_general_campuses_buildings_list.replace(
                        ':campusId',
                        params.row.id?.toString() ?? '',
                    )}
                >
                    <TableButton tooltip={'Épületek'} sx={{ marginRight: '5px' }}>
                        <Home />
                    </TableButton>
                </Link>
            </EndpointProvider>
        </>
    );
}
