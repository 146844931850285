import { GridColDef, GridColumns, GridRowClassNameParams, GridValueGetterParams } from '@mui/x-data-grid';
import instance from '../../../api';
import { formatNumberToThousands } from '../../../utils/AppConst';
import { endpoints } from '../../../utils/endPoints';
import { IForm } from '../../../utils/Interfaces/interfaces';
import OperationCellRenderer from './OperationCellRenderer';

const breadcrumbs = {
    scholarship: 'Ösztöndíjak',
    report: 'Ösztöndíj riport',
};

const header = (tenant: string | null) => {
    return {
        title: 'Ösztöndíjak',
        breadcrumbs: {},
        project: tenant || 'TAR',
    };
};

const valueGetter = (params: GridValueGetterParams, currency: string) => {
    const formattedValue = formatNumberToThousands(params.value || 0);
    return `${formattedValue} ${currency}`;
};

const columns = (semester?: string, archive?: boolean, currency?: string): GridColumns =>
    [
        {
            field: semester ? 'student' : 'semester',
            headerName: semester ? 'Hallgató' : 'Szemeszter',
            align: 'left' as const,
        },
        {
            field: 'groups',
            headerName: 'Csoportok',
            align: 'left' as const,
        },
        {
            field: 'professional',
            headerName: 'Szakmai ösztöndíj',
            valueGetter: (params: GridValueGetterParams) => valueGetter(params, currency ?? ''),
        },
        {
            field: 'project',
            headerName: 'Projekt ösztöndíj',
            valueGetter: (params: GridValueGetterParams) => valueGetter(params, currency ?? ''),
        },
        {
            field: 'total',
            headerName: 'Összes ösztöndíj',
            valueGetter: (params: GridValueGetterParams) => valueGetter(params, currency ?? ''),
        },
        {
            field: 'paid',
            headerName: 'Eddig kifizetve',
            valueGetter: (params: GridValueGetterParams) => valueGetter(params, currency ?? ''),
        },
        {
            field: 'remaining',
            headerName: 'Hátralévő összeg',
            valueGetter: (params: GridValueGetterParams) => valueGetter(params, currency ?? ''),
        },
        ...(!archive
            ? [
                  {
                      field: 'operations',
                      headerName: 'Műveletek',
                      align: 'center' as const,
                      sortable: false,
                      renderCell: OperationCellRenderer,
                  },
              ]
            : []),
    ].map((column: GridColDef) => ({
        headerAlign: 'center',
        align: 'right',
        flex: 1,
        ...column,
    }));

const service = (semester?: string) => ({
    filter: (form: IForm) => instance.get(endpoints.scholarships.report.list(semester || ''), { params: form }),
    groupSearch: (term: string) => instance.get(endpoints.scholarships.nameSearch(semester || '') + '?term=' + term),
    studentSearch: (term: string) => instance.get(endpoints.scholarships.report.studentSearch + '?term=' + term),
});

const tableStyling = {
    getRowClassName: (params: GridRowClassNameParams) => (params.id === -1 ? 'bold' : ''),
    sx: { '& .bold': { borderTop: '5px solid #E0E0E0', fontWeight: 'bold' } },
    style: {
        width: '100%',
    },
    autoHeight: true,
};

export { breadcrumbs, columns, header, service, tableStyling };
