import { endpoints } from '../../utils/endPoints';
import instance from '../../api';
import { IForm } from '../../utils/Interfaces/interfaces';

const trainingProgramRequirementCourseReferenceCodeService = {
    list: (levelId?: string, programId?: string, campusId?: string, semesterId?: number, form?: IForm) =>
        instance.get(
            endpoints.masterData.trainingPrograms.requirements.courseReferenceCode.list
                .replace('{levelId}', levelId ?? '')
                .replace('{programId}', programId ?? '')
                .replace('{campusId}', campusId ?? '') +
                '?semesterId=' +
                semesterId,
            { params: form },
        ),
    refCodeSearch: (levelId?: string, programId?: string, campusId?: string, term?: string) =>
        instance.get(
            endpoints.masterData.trainingPrograms.requirements.courseReferenceCode.refCodeSearch
                .replace('{levelId}', levelId ?? '')
                .replace('{programId}', programId ?? '')
                .replace('{campusId}', campusId ?? '') +
                '?term=' +
                term,
        ),
    create: (levelId?: string, programId?: string, campusId?: string, semesterId?: number, form?: IForm) =>
        instance.post(
            endpoints.masterData.trainingPrograms.requirements.courseReferenceCode.create
                .replace('{levelId}', levelId ?? '')
                .replace('{programId}', programId ?? '')
                .replace('{campusId}', campusId ?? '') +
                '?semesterId=' +
                semesterId,
            form,
        ),
    delete: (levelId?: string, programId?: string, campusId?: string, id?: string, semesterId?: number) =>
        instance.delete(
            endpoints.masterData.trainingPrograms.requirements.courseReferenceCode.delete
                .replace('{levelId}', levelId ?? '')
                .replace('{programId}', programId ?? '')
                .replace('{campusId}', campusId ?? '')
                .replace('{id}', id ?? '') +
                '?semesterId=' +
                semesterId,
        ),
};

export default trainingProgramRequirementCourseReferenceCodeService;
