import instance from '../../api';
import { IBaseRequirements } from '../../pages/MasterData/General/TrainingLevels/TrainingPrograms/Requirements/Base/BaseRequirements';
import { endpoints } from '../../utils/endPoints';

const trainingProgramRequirementsService = {
    campuses: (levelId?: string, programId?: string) =>
        instance.get(
            endpoints.masterData.trainingPrograms.requirements.campuses
                .replace('{levelId}', levelId ?? '')
                .replace('{programId}', programId ?? ''),
        ),
    campus: (levelId?: string, programId?: string, campusId?: string) =>
        instance.get(
            endpoints.masterData.trainingPrograms.requirements.campus
                .replace('{levelId}', levelId ?? '')
                .replace('{programId}', programId ?? '')
                .replace('{campusId}', campusId ?? ''),
        ),
    getBase: (levelId?: string, programId?: string, campusId?: string, semesterId?: number) =>
        instance.get(
            endpoints.masterData.trainingPrograms.requirements.getBase
                .replace('{levelId}', levelId ?? '')
                .replace('{programId}', programId ?? '')
                .replace('{campusId}', campusId ?? '') +
                '?semesterId=' +
                semesterId,
        ),
    saveBase: (
        levelId?: string,
        programId?: string,
        campusId?: string,
        semesterId?: number,
        form?: IBaseRequirements,
    ) =>
        instance.post(
            endpoints.masterData.trainingPrograms.requirements.saveBase
                .replace('{levelId}', levelId ?? '')
                .replace('{programId}', programId ?? '')
                .replace('{campusId}', campusId ?? '') +
                '?semesterId=' +
                semesterId,
            form,
        ),
};

export default trainingProgramRequirementsService;
