import { useState, useCallback, useEffect } from 'react';
import { GridColumnOrderChangeParams } from '@mui/x-data-grid-pro';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useColumnOrder = (columns: any[], sessionName?: string) => {
    const [columnOrder, setColumnOrder] = useState<string[]>([]);

    const getColumnOrderKey = useCallback(() => {
        return `column_order_${sessionName ?? window.location.pathname}`;
    }, [sessionName]);

    useEffect(() => {
        const defaultOrder = columns.map((col) => col.field as string);
        const storageKey = getColumnOrderKey();

        if (storageKey) {
            const savedOrder = sessionStorage.getItem(storageKey);
            if (savedOrder) {
                setColumnOrder(JSON.parse(savedOrder));
            } else {
                setColumnOrder(defaultOrder);
            }
        }
    }, [getColumnOrderKey, columns]);

    const handleColumnOrderChange = (params: GridColumnOrderChangeParams) => {
        const { field, targetIndex } = params;

        if (!columnOrder.includes(field)) return;

        const newOrder = [...columnOrder];
        const oldIndex = newOrder.indexOf(field);

        if (oldIndex !== -1) {
            newOrder.splice(oldIndex, 1);
            newOrder.splice(targetIndex, 0, field);
        }

        setColumnOrder(newOrder);

        const storageKey = getColumnOrderKey();
        sessionStorage.setItem(storageKey, JSON.stringify(newOrder));
    };

    const resetColumnOrder = () => {
        if (!columns) return;

        const defaultOrder = columns.map((col) => col.field);
        setColumnOrder(defaultOrder);

        const storageKey = getColumnOrderKey();
        sessionStorage.setItem(storageKey, JSON.stringify(defaultOrder));
    };

    const getOrderedColumns = () => {
        if (!columnOrder.length || !columns) return columns;
        return columnOrder.map((field) => columns.find((col) => col.field === field)).filter(Boolean);
    };

    return {
        columnOrder,
        handleColumnOrderChange,
        resetColumnOrder,
        getOrderedColumns,
    };
};

export default useColumnOrder;
