import { useCallback, useEffect, useState } from 'react';
import MCCLoading from '../../../../../../components/MCCLoading';
import { useParams } from 'react-router-dom';
import { Page, TabsCompleted, TextField, useFetch } from '@silinfo/front-end-template';
import trainingProgramRequirementsService from '../../../../../../services/masterData/trainingProgramRequirements';
import Grid from '@mui/material/Grid';
import BaseRequirements from './Base/BaseRequirements';
import CourseTypeRequirements from './CourseType/CourseTypeRequirements';
import RequiredCourses from './RequiredCourses';
import { clientEndPoints } from '../../../../../../utils/clientEndPoints';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';
import trainingLevelService from '../../../../../../services/masterData/trainingLevels';
import trainingProgramsService from '../../../../../../services/masterData/trainingPrograms';
import { Option } from '../../../../../../utils/AppConst';
import { useTenant } from '../../../../../../components/TenantContext';
import Autocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import { endpoints } from '../../../../../../utils/endPoints';

const Input = (params: AutocompleteRenderInputParams) => <TextField {...params} label="Szemeszter" />;
export type SemesterOption = Option<number> & { active?: boolean; closed?: boolean };

export default function Requirements() {
    const { id, programId, campusId } = useParams();
    const [semester, setSemester] = useState<SemesterOption>();
    const { data: semesterOptions, loading: semestersLoading } = useFetch<SemesterOption[]>(
        endpoints.masterData.trainingPrograms.requirements.semesters
            .replace('{levelId}', id ?? '')
            .replace('{programId}', programId ?? ''),
        '',
    );
    const { user } = useSelector((state: RootState) => state.auth);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [campus, setCampus] = useState<{ id: number; name: string } | null>(null);
    const [levelName, setLevelName] = useState('');
    const [programName, setProgramName] = useState('');
    const tenant = useTenant();

    const handleSemesterChange = useCallback(
        (_: unknown, value: Option<number> | null) => {
            setSemester(value ?? undefined);
            setRefresh((prev) => !prev);
        },
        [setSemester],
    );

    useEffect(() => {
        if (semestersLoading || semesterOptions.length < 1) return;
        const activeSemester = semesterOptions.find((item) => item.active);
        if (activeSemester) setSemester(activeSemester);
    }, [semestersLoading, semesterOptions]);

    useEffect(() => {
        trainingProgramRequirementsService.campus(id, programId, campusId).then((response) => {
            setCampus(response.data);
            setLoading(false);
        });

        trainingLevelService.trainingLevels().then((response) => {
            setLevelName(response.data.find((element: Option) => element.value == id).label);
        });

        trainingProgramsService.trainingPrograms().then((response) => {
            setProgramName(response.data.find((element: Option) => element.value == programId).label);
        });
    }, [campusId, id, programId, refresh]);

    if (loading) {
        return <MCCLoading />;
    }

    const header = {
        project: tenant || 'TAR',
        title: 'Képzési programok',
        breadcrumbs: {
            'masterData': 'Törzsadatok',
            [clientEndPoints.md_general]: 'Általános',
            [clientEndPoints.md_training_levels_list]: levelName + ' szint',
            [clientEndPoints.md_training_levels_programs_list.replace(':id', id?.toString() || '')]:
                programName + ' program',
            [clientEndPoints.md_training_levels_programs_requirements_campuses
                .replace(':id', id?.toString() || '')
                .replace(':programId', programId?.toString() || '')]: campus?.name + ' követelményei',
        },
    };

    const tabContents = () => [
        {
            label: 'Általános követelmények',
            value: <BaseRequirements readonly={!!user.archive} semester={semester} />,
        },
        {
            label: 'Kötelező kurzusok',
            value: <RequiredCourses readonly={!!user.archive} semester={semester} refresh={refresh} />,
        },
        {
            label: 'Választható kurzustípusok',
            value: <CourseTypeRequirements readonly={!!user.archive} semester={semester} refresh={refresh} />,
        },
    ];

    if (semestersLoading || !semester) {
        return <MCCLoading />;
    }

    return (
        <Page header={header}>
            <Grid item xs={12}>
                <Autocomplete
                    options={semesterOptions}
                    value={semester}
                    onChange={handleSemesterChange}
                    size="small"
                    renderInput={Input}
                />
            </Grid>
            <Grid item xs={12}>
                <TabsCompleted tabContents={tabContents()} />
            </Grid>
        </Page>
    );
}
