import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import ConnectedTvIcon from '@mui/icons-material/ConnectedTv';
import MicIcon from '@mui/icons-material/Mic';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import SpeakerIcon from '@mui/icons-material/Speaker';
import VideocamIcon from '@mui/icons-material/Videocam';
import VoiceChatIcon from '@mui/icons-material/VoiceChat';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import { CKEditor, Loading } from '@silinfo/front-end-template';
import { AxiosResponse } from 'axios';
import { FastField, FormikProps } from 'formik';
import { useEffect, useState, useCallback, useContext, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Autocomplete from '../../../../components/Form/Autocomplete';
import { FieldGenerator } from '../../../../components/Form/FieldGenerator';
import { ICourseInfo } from '../../../../interface/ICourseInfo';
import { initialSemesterInfo, ISemesterInfo } from '../../../../interface/ISemesterInfo';
import courseBaseDataService from '../../../../services/courseManagement/courseBaseData';
import courseListService from '../../../../services/courseManagement/courseList';
import coursesService from '../../../../services/masterData/courses';
import { Option } from '../../../../utils/AppConst';
import { CampusView, IEditor, IForm, ItemEntity } from '../../../../utils/Interfaces/interfaces';
// import Address from '../../Address';
import GuestPerformerAutocomplete from '../GuestPerformerAutocomplete';
import { eventTypeOptions } from '../utils';
import RepeatInfo from './RepeatInfo';
import { createUserOptions, getScoringTypeText } from './../BaseData/utils';
import courseEventService from '../../../../services/courseManagement/courseEvent';
import { theme } from '../../../../utils/theme';
import { create } from '../../../../store/notification';
import { endpoints } from '../../../../utils/endPoints';
import { getDefaultStartDate, IAddress, rawInternalFields, removeHtmlTags, useDynamicFields } from './utils';
import EventDynamicFields from '../../../../components/EventDynamicFields';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { RoomContext } from './RoomSearch/RoomContext';
import { getFirstEmptyIndex } from './RoomSearch/utils';
import RoomSearch from './RoomSearch';
import useMediaQuery from '@mui/material/useMediaQuery';
import DialogTitle from '@mui/material/DialogTitle';
import { debounce } from '@mui/material/utils';

interface IEventForm extends IForm {
    eventType: string;
    comment: string;
    instructors: never[];
    eventStart: string;
    eventEnd: string;
    campus: string;
    building: string;
    floor: string;
    room: string;
    item: string;
    isRepeated: string;
    numberOfRepetitions: number;
    pointMin: null;
    pointMax: null;
    guestInstructors: string[];
    external: boolean;
    category: string;
    organisingTrainingCenter: string;
    organisers: string[];
    addresses: IAddress[];
    presentsPerLocation: boolean;
    communicationPerLocation: boolean;
    cateringPerLocation: boolean;
    itNeedPerLocation: boolean;
    roomLayoutPerLocation: boolean;
}

const useStyles = makeStyles(() => ({
    richTextEditor: {
        '& .ck-editor__main > .ck-editor__editable': {
            height: '10vh',
        },
    },
}));

export default function EventForm({
    objectId,
    courseId,
    readOnly,
    ...props
}: FormikProps<IEventForm> & {
    handleClose: () => void;
    objectId?: number;
    courseId: number;
    readOnly: boolean | undefined;
}) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [searchParams] = useSearchParams();
    const courseTenant = searchParams.get('courseTenant');
    const [campusOptions, setCampusOptions] = useState<Option[]>();
    const [itemOptions, setItemOptions] = useState<string[]>([]);
    const [courseInfo, setCourseInfo] = useState<ICourseInfo | undefined>();
    const [semesterInfo, setSemesterInfo] = useState<ISemesterInfo>(initialSemesterInfo);
    const { values, setFieldValue, errors } = props;
    const scoringType = courseInfo?.scoringType || 'point';
    const text = getScoringTypeText(scoringType);
    const [_roomTakenWarning, setRoomTakenWarning] = useState<string>('');
    const DynamicAddressFields = (i: number) => useDynamicFields(i, campusOptions || [], false);

    const startDate = getDefaultStartDate();
    const endDate = moment(startDate).add(30, 'minutes').format('YYYY-MM-DDTHH:mm');
    const addressCount = values.addresses.length;
    const external = values.external;
    const { currentLocationIndex, selectedRoom, setTrainingCenterOptions, setSelectedDates, setChangedRoomId } =
        useContext(RoomContext);
    const firstEmptyIndex = getFirstEmptyIndex(external, values.addresses);

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const debouncedDates = useMemo(
        () =>
            debounce((values) => {
                setSelectedDates({ startDate: values.start, endDate: values.end });
            }, 1000),
        [setSelectedDates],
    );

    useEffect(() => {
        if (selectedRoom && addressCount > 0 && campusOptions && !external) {
            const selectedRoomTrainingCenter = campusOptions.find(
                (tc) => tc.value.toString() === selectedRoom.trainingCenterId.toString(),
            );
            if (selectedRoomTrainingCenter) {
                setFieldValue(`addresses.${currentLocationIndex}.campus`, selectedRoomTrainingCenter.value);
            }
        }
    }, [addressCount, selectedRoom, setFieldValue, campusOptions, currentLocationIndex, external, firstEmptyIndex]); // Teremkereső szinkron

    useEffect(() => {
        if (!objectId) {
            debouncedDates({ start: values.eventStart, end: values.eventEnd });
        } else if (values.addresses?.[addressCount - 1]?.room) {
            setChangedRoomId(values.addresses?.[addressCount - 1]?.room as unknown as number);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [objectId, debouncedDates]);

    useEffect(() => {
        if (values.eventStart && values.eventEnd) {
            debouncedDates({ start: values.eventStart, end: values.eventEnd });
        }
    }, [values.eventStart, values.eventEnd, debouncedDates]);

    useEffect(() => {
        setFieldValue('external', values.external);
        if (values.external) {
            setRoomTakenWarning('');
        }
        if (!values.external && values.room && values.eventStart && values.eventEnd) {
            const params = {
                startDate: values.eventStart,
                endDate: values.eventEnd,
                roomId: values.room,
                checkBusy: 1,
            };
            courseEventService
                .getRoomTaken(params)
                .then((response: AxiosResponse) => {
                    if (!response.data['free']) {
                        const errorString =
                            response.data['creator'] !== ''
                                ? `Ehhez a teremhez és idősávhoz ${response.data['creator']} már elindított egy igénylést.`
                                : 'Ehhez a teremhez és idősávhoz már van elindított igénylés.';
                        setRoomTakenWarning(errorString);
                    } else {
                        setRoomTakenWarning('');
                    }
                })
                .catch(() => dispatch(create({ type: 'error', message: 'Hibás dátum formátum!' })));
        }
    }, [values.room, values.eventStart, values.eventEnd, values.external, setFieldValue, dispatch]);

    useEffect(() => {
        courseBaseDataService.get(courseId, courseTenant).then((response) => {
            setCourseInfo(response.data.data);
        });
        coursesService.semesterInfo(courseId).then((response) => {
            setSemesterInfo(response.data.data);
        });
        courseListService.campuses().then((response: AxiosResponse) => {
            const filtered = response.data.filter((campus: CampusView) => campus.active == true);
            const unFiltered = response.data;
            if (!objectId) {
                setCampusOptions(filtered);

                setFieldValue('eventStart', startDate);
                setFieldValue('eventEnd', endDate);
            } else {
                const selectedCampus = unFiltered.find((campus: CampusView) => campus.value === Number(values.campus));
                if (!selectedCampus) {
                    setCampusOptions(filtered);
                } else {
                    setCampusOptions(selectedCampus?.active ? filtered : [...filtered, selectedCampus]);
                }
            }

            setTrainingCenterOptions(filtered);
        });
    }, [courseId, values.campus, objectId, courseTenant, setFieldValue, startDate, endDate, setTrainingCenterOptions]);

    useEffect(() => {
        if (values.room) {
            courseListService.items(values.room).then((response) => {
                setItemOptions(
                    response.data.map((item: ItemEntity) => {
                        return item.category;
                    }),
                );
            });
        }
    }, [values.room]);

    const handleGuestPerformerChange = useCallback(
        (values: string[]) => setFieldValue('guestInstructors', values),
        [setFieldValue],
    );

    if (!courseInfo) {
        return <Loading noHeight />;
    }
    return (
        <>
            {FieldGenerator({
                name: 'eventType',
                label: 'Esemény típusa',
                type: 'select',
                fieldType: 'base',
                options: eventTypeOptions,
                format: { xs: 12 },
                ...props,
                props: {
                    disabled: readOnly,
                },
            })}
            <Grid item xs={12} className={classes.richTextEditor}>
                {readOnly ? (
                    <>
                        <Typography variant="body2" sx={{ marginLeft: '5px', color: 'rgba(0,0,0, 0.35)' }}>
                            Tartalom
                        </Typography>
                        <Box
                            component="section"
                            sx={{
                                p: 2,
                                border: '1px solid rgba(0,0,0, 0.25)',
                                paddingTop: '0px',
                                paddingBottom: '0px',
                            }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    wordBreak: 'break-word',
                                    overflowWrap: 'break-word',
                                    whiteSpace: 'normal',
                                    color: 'grey',
                                }}
                                dangerouslySetInnerHTML={{ __html: values.comment }}
                            />
                        </Box>
                    </>
                ) : (
                    <FastField as="div">
                        <Typography variant="body2" sx={{ marginLeft: '5px', marginBottom: '5px' }}>
                            Tartalom
                        </Typography>
                        <div
                            style={
                                errors.comment || removeHtmlTags(values.comment).length > 10000
                                    ? {
                                          border: '0.5px solid red',
                                          borderStyle: 'solid',
                                      }
                                    : {}
                            }
                        >
                            <CKEditor
                                label="Tartalom"
                                name="comment"
                                data={values.comment}
                                onChange={(_: unknown, editor: IEditor) => {
                                    setFieldValue('comment', editor.getData());
                                }}
                                disabled={readOnly}
                            />
                        </div>
                    </FastField>
                )}
            </Grid>
            {FieldGenerator({
                name: 'category',
                label: 'Kategória',
                type: 'backendSelect',
                fieldType: 'base',
                url: endpoints.courseManagement.courseList.listCategories,
                format: { xs: 12 },
                justValue: true,
                props: {
                    renderOption: (props: React.HTMLAttributes<HTMLLIElement>, option: Option) => (
                        <li {...props} style={{ color: 'black' }}>
                            {option.label}
                        </li>
                    ),
                    disabled: readOnly,
                },
                ...props,
            })}
            {values.external && readOnly
                ? FieldGenerator({
                      name: 'campusName',
                      label: 'Szervező képzési központ (Campus)',
                      type: 'text',
                      fieldType: 'base',
                      format: { xs: 12 },
                      props: {
                          disabled: readOnly,
                      },
                      ...props,
                  })
                : FieldGenerator({
                      name: 'organisingTrainingCenter',
                      label: 'Szervező képzési központ (Campus)',
                      type: 'select',
                      fieldType: 'base',
                      options: campusOptions ?? [],
                      format: { xs: 12 },
                      props: {
                          renderOption: (props: React.HTMLAttributes<HTMLLIElement>, option: Option) => (
                              <li {...props} style={{ color: 'black' }}>
                                  {option.label}
                              </li>
                          ),
                          disabled: readOnly,
                          justValue: true,
                      },
                      ...props,
                  })}
            {FieldGenerator({
                name: 'organisers',
                label: 'Szervező szervezeti egység',
                type: 'backendSelect',
                fieldType: 'base',
                url: endpoints.courseManagement.courseList.listDepartments,
                format: { xs: 12 },
                justValue: true,
                multiple: true,
                props: {
                    renderOption: (props: React.HTMLAttributes<HTMLLIElement>, option: Option) => (
                        <li {...props} style={{ color: 'black' }}>
                            {option.label}
                        </li>
                    ),
                    disabled: readOnly,
                },
                ...props,
            })}
            <Grid item xs={12}>
                <Autocomplete
                    getter={courseListService.userSearchTeacher}
                    textFieldProps={{
                        label: 'Oktató',
                    }}
                    autocompleteProps={{
                        value: props.values.instructors || [],
                        onChange: (_, v) => {
                            props.setFieldValue('instructors', v);
                        },
                        multiple: true,
                        disabled: readOnly,
                    }}
                    createOptions={createUserOptions}
                    errorMessage={props.errors.instructors as string}
                />
            </Grid>
            {FieldGenerator({
                name: 'eventStart',
                label: 'Esemény kezdete',
                type: 'datetime-local',
                fieldType: 'base',
                format: { xs: 12, sm: 6 },
                props: {
                    inputProps: {
                        max: '9999-12-31T23:59',
                    },
                    disabled: readOnly,
                },
                ...props,
            })}
            {FieldGenerator({
                name: 'eventEnd',
                label: 'Esemény vége',
                type: 'datetime-local',
                fieldType: 'base',
                format: { xs: 12, sm: 6 },
                props: {
                    inputProps: {
                        max: '9999-12-31T23:59',
                    },
                    sx: {
                        '& .MuiFormHelperText-root': {
                            color: theme.palette.warning.main,
                        },
                    },
                    disabled: readOnly,
                },
                ...props,
            })}
            <Grid item xs={12}>
                <GuestPerformerAutocomplete
                    value={props.values.guestInstructors || []}
                    onChange={handleGuestPerformerChange}
                    disabled={readOnly}
                />
            </Grid>
            {FieldGenerator({
                name: 'external',
                label: 'Külső helyszín megadása',
                type: 'checkbox',
                format: { xs: 12, sm: 6 },
                fieldType: 'base',
                props: {
                    disabled: readOnly,
                    checked: values.external,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                        props.setFieldValue('external', e.target.checked);
                    },
                },
                options: {
                    checked: true,
                    unChecked: false,
                },
                ...props,
            })}
            {props.errors.addresses && (
                <Grid item xs={12}>
                    <Typography
                        style={{
                            color: '#ff6961',
                            textAlign: 'center',
                        }}
                    >
                        {props.errors.addresses as string}
                    </Typography>
                </Grid>
            )}
            {campusOptions && (
                <EventDynamicFields
                    label="helyszín"
                    keyName={'addresses'}
                    inputFields={
                        props.values.external
                            ? [{ type: 'location', name: 'location', gifts: [], itRequests: [] }]
                            : DynamicAddressFields
                    }
                    rawFields={
                        props.values.external
                            ? [{ type: 'location', name: 'location', gifts: [], itRequests: [] }]
                            : rawInternalFields
                    }
                    readOnly={readOnly}
                />
            )}
            <Grid item container spacing={2}>
                {itemOptions.includes('projector') ? (
                    <Grid item>
                        <Tooltip title="Projektor">
                            <VideocamIcon />
                        </Tooltip>
                    </Grid>
                ) : (
                    <></>
                )}
                {itemOptions.includes('speaker') ? (
                    <Grid item>
                        <Tooltip title="Hangfal">
                            <SpeakerIcon />
                        </Tooltip>
                    </Grid>
                ) : (
                    <></>
                )}
                {itemOptions.includes('microphone') ? (
                    <Grid item>
                        <Tooltip title="Mikrofon">
                            <MicIcon />
                        </Tooltip>
                    </Grid>
                ) : (
                    <></>
                )}
                {itemOptions.includes('tv') ? (
                    <Grid item>
                        <Tooltip title="TV">
                            <ConnectedTvIcon />
                        </Tooltip>
                    </Grid>
                ) : (
                    <></>
                )}
                {itemOptions.includes('eboard') ? (
                    <Grid item>
                        <Tooltip title="Etábla">
                            <AspectRatioIcon />
                        </Tooltip>
                    </Grid>
                ) : (
                    <></>
                )}
                {itemOptions.includes('webcam') ? (
                    <Grid item>
                        <Tooltip title="Webkamera">
                            <VoiceChatIcon />
                        </Tooltip>
                    </Grid>
                ) : (
                    <></>
                )}
                {itemOptions.includes('other') ? (
                    <Grid item>
                        <Tooltip title="Egyéb">
                            <MiscellaneousServicesIcon />
                        </Tooltip>
                    </Grid>
                ) : (
                    <></>
                )}
            </Grid>
            {!objectId && (
                <>
                    {FieldGenerator({
                        name: 'isRepeated',
                        label: 'Ismétlődő',
                        type: 'checkbox',
                        format: { xs: 12 },
                        props: {
                            disabled: readOnly,
                        },
                        ...props,
                    })}
                    {props.values.isRepeated === '1'
                        ? FieldGenerator({
                              name: 'numberOfRepetitions',
                              label: 'Ismétlődések száma',
                              type: 'select',
                              fieldType: 'base',
                              format: { xs: 12, sm: 6 },
                              options: numberOfRepetitionsOptions(),
                              ...props,
                              props: {
                                  disabled: readOnly,
                              },
                          })
                        : ''}
                </>
            )}
            {courseInfo.customCoursePointPerEvent === 1 && (
                <>
                    {FieldGenerator({
                        name: 'pointMin',
                        label: 'Min. ' + text,
                        format: { xs: 6 },
                        fieldType: 'base',
                        props: {
                            disabled: readOnly,
                        },
                        ...props,
                    })}
                    {FieldGenerator({
                        name: 'pointMax',
                        label: 'Max. ' + text,
                        format: { xs: 6 },
                        fieldType: 'base',
                        props: {
                            disabled: readOnly,
                        },
                        ...props,
                    })}
                </>
            )}
            {!objectId && props.values.isRepeated === '1' && (
                <RepeatInfo
                    numberOfRepetitions={Number(props.values.numberOfRepetitions)}
                    eventStart={props.values.eventStart}
                    eventEnd={props.values.eventEnd}
                    semesterInfo={semesterInfo}
                />
            )}
            {isMobile && (
                <>
                    <Box
                        sx={{
                            backgroundColor: 'background.paper',
                            boxShadow: 4,
                            borderRadius: 2,
                            maxWidth: '800px',
                            width: '100%',
                            maxHeight: '90vh',
                            display: 'flex',
                            flexDirection: 'column',
                            marginTop: '1rem',
                        }}
                    >
                        <DialogTitle>Teremkereső</DialogTitle>
                        <Box
                            sx={{
                                flex: 1,
                                overflowY: 'auto',
                            }}
                        >
                            <RoomSearch objectId={objectId} />
                        </Box>
                    </Box>
                </>
            )}
        </>
    );
}

function numberOfRepetitionsOptions(): Option[] {
    const N = 14,
        a = Array(N);
    let i = 1;
    while (i < N)
        a[i++] = {
            value: i.toString(),
            label: i.toString(),
        };
    return a;
}
