import { AutocompleteProps } from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import { Filter, Input, MultiselectField, Table, TextField } from '@silinfo/front-end-template';
import { Children, memo } from 'react';
import { findLabelByValue, Option } from '../../../../utils/AppConst';
import { Student } from '../../../../utils/Interfaces/interfaces';
import { TableProps, ViewProps } from './Form/types';

const areEqual = (prev: TableProps<Student>, next: TableProps<Student>) => {
    return JSON.stringify(prev.rows) === JSON.stringify(next.rows) && prev.loading === next.loading;
};

const MemoTable = memo(
    (table: TableProps<Student>) => (
        <Grid item xs={12}>
            <Table
                style={{
                    width: '100%',
                }}
                autoHeight={true}
                {...table}
            />
        </Grid>
    ),
    areEqual,
);
MemoTable.displayName = 'MemoTable';

export default function View({ filter, table, changeForm }: ViewProps<Student>) {
    const FilterTextField = (props: Input) => (
        <Grid item xs={12} sm={6} md={4}>
            <TextField {...props} value={filter.form[props.name] || ''} onChange={changeForm} />
        </Grid>
    );
    const FilterSelect = (props: { name: string; label: string; options: Option[] }) => (
        <Grid item xs={12} sm={6} md={4}>
            <TextField select {...props} value={filter.form[props.name] || ''} onChange={changeForm} />
        </Grid>
    );
    const FilterMultiselect = ({
        name,
        label,
        options,
        props,
    }: {
        name: string;
        label: string;
        options: Option[];
        props?: AutocompleteProps<Option, true, false, false>;
    }) => (
        <Grid item xs={12} sm={6} md={4}>
            <MultiselectField
                label={label}
                onChange={(_, value) =>
                    changeForm({ target: { name, value: value.map((elem) => elem.value || elem) } })
                }
                value={(filter.form[name] as unknown[]) || []}
                options={options}
                getOptionLabel={(option) => findLabelByValue(option, options)}
                isOptionEqualToValue={(option, value) =>
                    option.value === value.value || option.value === value || option === value
                }
                filterSelectedOptions
                {...props}
            />
        </Grid>
    );

    return (
        <Grid item container spacing={2}>
            <Grid item xs={12}>
                <Filter {...filter}>
                    <Grid item container spacing={1}>
                        {Children.toArray(
                            filter.fields.map((field) => {
                                switch (field.type) {
                                    case 'select':
                                        return FilterSelect(field);
                                    case 'multiselect':
                                        return FilterMultiselect(field);
                                    case 'phone':
                                    case 'fromTo':
                                        return <></>;
                                    default:
                                        return FilterTextField(field);
                                }
                            }),
                        )}
                    </Grid>
                </Filter>
                <MemoTable {...table} />
            </Grid>
        </Grid>
    );
}
