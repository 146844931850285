import Add from '@mui/icons-material/Add';
import Close from '@mui/icons-material/Close';
import HourglassEmpty from '@mui/icons-material/HourglassEmpty';
import MoreVert from '@mui/icons-material/MoreVert';
import { LoadingButton as Button } from '@mui/lab';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { AxiosResponse } from 'axios';
import moment from 'moment';
import * as React from 'react';
import { Dispatch, SetStateAction } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import courseEnrollmentService from '../../services/studentServices/courseEnrollment';
import { RootState } from '../../store';
import { create } from '../../store/notification';
import CourseDialog from './CourseDialog';
import RequestButton from './RequestButton';
import { IPrerequisites } from './types';
import PrerequisiteModal from './PrerequisiteModal';
import ErrorOutline from '@mui/icons-material/ErrorOutline';

type isEnrolled = 'yes' | 'no' | 'waiting_list';
interface Course {
    courseCode: string;
    courseName: string;
    isEnrolled: isEnrolled;
    enrollmentStartDate: string;
    enrollmentEndDate: string;
    enrollmentCurrentDate: string;
    headCount: number;
    maxHeadCount: number;
    hasOpenCourseDismissalRequest: boolean;
    hasOpenCourseEnrollmentRequest: boolean;
    courseTenant: string | number;
    courseType: string;
    externalId: number;
    waitlist: boolean;
}

type ButtonRendererProps = {
    params: Pick<GridRenderCellParams<unknown, Course>, 'row' | 'id'>;
    setRefresh: Dispatch<SetStateAction<boolean>>;
    handleClose: () => void;
};

// Check from and to dates if today is between them
const dateCheck = (from: Date, to: Date, current: Date) => {
    to.setHours(23, 59, 59, 999);
    from.setHours(0, 0, 0, 0);
    return current.getTime() >= from.getTime() && current.getTime() <= to.getTime();
};

function ButtonRenderer({ params, setRefresh, handleClose }: ButtonRendererProps) {
    const [open, setOpen] = React.useState(false);
    const [prerequisites, setPrerequisites] = React.useState<IPrerequisites[]>([]);
    const { user } = useSelector((state: RootState) => state.auth);
    const { count } = useSelector((state: RootState) => state.loading);

    const { isEnrolled, hasOpenCourseDismissalRequest, hasOpenCourseEnrollmentRequest } = params.row;
    const dispatch = useDispatch();

    if (!user.student || user.archive) return <i>Nem módosítható.</i>;

    const refresh = (ret: AxiosResponse) => {
        if (!ret?.data?.success) {
            dispatch(create({ type: 'error', message: 'Hiba: ' + (ret?.data?.message || 'Ismeretlen hiba') }));
        } else {
            dispatch(create({ type: 'success', message: 'Sikeres mentés!' }));
            setRefresh((prev) => !prev);
        }
        handleClose();
    };

    const handleError = () => dispatch(create({ type: 'error', message: 'Hiba a mentés során!' }));

    // Ha a hallgató be van iratkozva a kurzusra:
    if (isEnrolled === 'yes') {
        if (params.row?.enrollmentStartDate && params.row?.enrollmentEndDate) {
            const enrollmentStartDate = moment(params.row?.enrollmentStartDate).toDate();
            const enrollmentEndDate = moment(params.row?.enrollmentEndDate).toDate();
            const enrollmentCurrentDate = moment(params.row?.enrollmentCurrentDate).toDate();

            // Ha a kurzusfelvételi időszak lezárult:
            if (!dateCheck(enrollmentStartDate, enrollmentEndDate, enrollmentCurrentDate)) {
                // Ha a hallgatónak már van nyitott kurzusleadási kérvénye:
                return hasOpenCourseDismissalRequest ? (
                    <Tooltip title="Már van azonos típusú nyitott kérvényed">
                        <div>
                            <Button color="error" disabled startIcon={<Close />} loadingPosition="start">
                                Leadás
                            </Button>
                        </div>
                    </Tooltip>
                ) : (
                    // Ha a hallgatónak még nincs nyitott kurzusleadási kérvénye:
                    <RequestButton
                        type="dismiss"
                        courseId={params.id.toString()}
                        externalCourseId={params.row.externalId.toString()}
                        courseCode={params.row.courseCode}
                        courseName={params.row.courseName}
                        courseTenant={params.row.courseTenant.toString()}
                        refresh={() => {
                            handleClose();
                            setRefresh((prev) => !prev);
                        }}
                        opener={
                            <Button loading={count > 0} loadingPosition="start" color="error" startIcon={<Close />}>
                                Leadás
                            </Button>
                        }
                    />
                );
            }
        }

        // Ha a kurzusfelvételi időszak még nem zárult le:
        return (
            <Button
                loading={count > 0}
                color="error"
                startIcon={<Close />}
                loadingPosition="start"
                onClick={() =>
                    courseEnrollmentService
                        .dismissal({
                            course: params.id,
                            courseTarId: params.row.externalId,
                            courseTenant: params.row.courseTenant,
                        })
                        .then(refresh)
                        .catch(handleError)
                }
            >
                Leadás
            </Button>
        );
    }
    if (params.row?.enrollmentStartDate && params.row?.enrollmentEndDate) {
        const enrollmentStartDate = moment(params.row?.enrollmentStartDate).toDate();
        const enrollmentEndDate = moment(params.row?.enrollmentEndDate).toDate();
        const enrollmentCurrentDate = moment(params.row?.enrollmentCurrentDate).toDate();
        if (!dateCheck(enrollmentStartDate, enrollmentEndDate, enrollmentCurrentDate)) {
            if (params.row.headCount >= params.row.maxHeadCount) {
                if (!params.row.waitlist) {
                    return (
                        <Button disabled color="inherit" startIcon={<ErrorOutline sx={{ color: '#cacaca' }} />}>
                            Nincs szabad hely
                        </Button>
                    );
                }
            }
            return hasOpenCourseEnrollmentRequest ? (
                <Tooltip title="Már van azonos típusú nyitott kérvényed">
                    <div>
                        <Button color="error" disabled startIcon={<Close />} loadingPosition="start">
                            Jelentkezés
                        </Button>
                    </div>
                </Tooltip>
            ) : (
                <>
                    <RequestButton
                        type="enroll"
                        refresh={() => {
                            handleClose();
                            setRefresh((prev) => !prev);
                        }}
                        courseCode={params.row.courseCode}
                        courseName={params.row.courseName}
                        courseId={params.id.toString()}
                        externalCourseId={params.row.externalId.toString()}
                        courseTenant={params.row.courseTenant.toString()}
                        opener={
                            <Button loading={count > 0} loadingPosition="start" color="success" startIcon={<Add />}>
                                Jelentkezés
                            </Button>
                        }
                    />
                </>
            );
        }
    }

    if (params.row.headCount >= params.row.maxHeadCount) {
        if (isEnrolled === 'waiting_list') {
            return (
                <Button
                    loading={count > 0}
                    color="error"
                    startIcon={<Close />}
                    loadingPosition="start"
                    onClick={() =>
                        courseEnrollmentService
                            .dismissal({
                                course: params.id,
                                courseTarId: params.row.externalId,
                                courseTenant: params.row.courseTenant,
                            })
                            .then(refresh)
                            .catch(handleError)
                    }
                >
                    Leadás
                </Button>
            );
        }

        if (params.row.waitlist) {
            return (
                <>
                    <Button
                        loadingPosition="start"
                        startIcon={<HourglassEmpty />}
                        loading={count > 0}
                        color="success"
                        onClick={() =>
                            courseEnrollmentService
                                .enrollment({
                                    course: params.id,
                                    courseTarId: params.row.externalId,
                                    type: 'waiting_list',
                                    courseTenant: params.row.courseTenant,
                                })
                                .then((ret) => {
                                    const prerequisitesRes =
                                        ret.data?.prerequisites ?? ret.data?.result?.prerequisites ?? [];
                                    if (!ret.data.success && prerequisites.length > 0) {
                                        setOpen(true);
                                        setPrerequisites(prerequisitesRes);
                                    } else {
                                        refresh(ret);
                                    }
                                })
                                .catch(handleError)
                        }
                    >
                        Várólista
                    </Button>
                    <PrerequisiteModal
                        open={open}
                        courseName={params.row.courseName}
                        courseCode={params.row.courseCode}
                        prerequisites={prerequisites}
                        handleClose={handleClose}
                    />
                </>
            );
        } else {
            return (
                <Button disabled color="inherit" startIcon={<ErrorOutline sx={{ color: '#cacaca' }} />}>
                    Nincs szabad hely
                </Button>
            );
        }
    }
    return (
        <>
            <Button
                loading={count > 0}
                loadingPosition="start"
                color="success"
                startIcon={<Add />}
                onClick={() =>
                    courseEnrollmentService
                        .enrollment({
                            course: params.id,
                            courseTarId: params.row.externalId,
                            type: 'normal',
                            courseTenant: params.row.courseTenant,
                        })
                        .then((ret) => {
                            const prerequisitesRes = ret.data?.prerequisites ?? ret.data?.result?.prerequisites ?? [];
                            if (!ret.data.success && prerequisites.length > 0) {
                                setOpen(true);
                                setPrerequisites(prerequisitesRes);
                            } else {
                                refresh(ret);
                            }
                        })
                        .catch(handleError)
                }
            >
                Jelentkezés
            </Button>
            <PrerequisiteModal
                open={open}
                courseName={params.row.courseName}
                courseCode={params.row.courseCode}
                prerequisites={prerequisites}
                handleClose={handleClose}
            />
        </>
    );
}

type OperationsCellRendererProps = {
    params: Pick<GridRenderCellParams, 'row' | 'id'>;
    setRefresh: Dispatch<SetStateAction<boolean>>;
    isEnrolled: boolean;
};

export default function OperationsCellRenderer({ params, setRefresh, isEnrolled }: OperationsCellRendererProps) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton onClick={handleClick} size="small">
                <MoreVert />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <Grid onKeyDown={(e) => e.stopPropagation()} item container xs={12} flexDirection="column">
                    {isEnrolled && !params.row.enrollmentStartIsAfterNow && (
                        <Grid item>
                            <ButtonRenderer params={params} setRefresh={setRefresh} handleClose={handleClose} />
                        </Grid>
                    )}
                    <Grid item>
                        <CourseDialog id={params.id + ''} />
                    </Grid>
                </Grid>
            </Menu>
        </>
    );
}
