import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { FormikListPage, Loading, TableEditButton } from '@silinfo/front-end-template';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ExportButton from '../../../components/Buttons/ExportButton';
import langExamReportService from '../../../services/administration/langExamReport';
import languageExamRequirements from '../../../services/masterData/languageExamRequirements';
import trainingLevelsService from '../../../services/masterData/trainingLevels';
import trainingProgramsService from '../../../services/masterData/trainingPrograms';
import { RootState } from '../../../store';
import { Option } from '../../../utils/AppConst';
import { clientEndPoints } from '../../../utils/clientEndPoints';
import { IInfo } from '../../../utils/Interfaces/IInfo';
import { onlyActiveStudentsOptions, validatedOptions } from '../../MasterData/General/Students/Form/options';
import LangExamTableEditButton from './LangExamTableEditButton';
import { initialLangExamReportFilterState, LangExamReportFilter } from './types';
import { SESSION_KEY } from './utils';
import { useTenant } from '../../../components/TenantContext';

export default function LangExamReport() {
    const [refresh, setRefresh] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [options, setOptions] = useState<{
        categories: Option[];
        types: Option[];
        levels: Option[];
        languages: Option[];
    }>({
        categories: [],
        types: [
            { value: 'general', label: 'Általános' },
            { value: 'professional', label: 'Szakmai' },
        ],
        levels: [],
        languages: [],
    });
    const [trainingLevelOptions, setTrainingLevelOptions] = useState<Option[]>([]);
    const [trainingProgramOptions, setTrainingProgramOptions] = useState<Option[]>([]);
    const [info, setInfo] = useState<IInfo<LangExamReportFilter>>(initialLangExamReportFilterState);
    const { accessTree } = useSelector((state: RootState) => state.accessManagement);
    const { user } = useSelector((state: RootState) => state.auth);
    const tenant = useTenant();
    const refreshing = () => setRefresh(!refresh);

    const header = {
        breadcrumbs: {
            'admin': 'Adminisztráció',
            [clientEndPoints.admin_lang_exam_report_list]: 'Nyelvvizsga riport',
            'languageExams': 'Nyelvvizsgák',
        },
        title: 'Nyelvvizsga riport',
        project: tenant || 'TAR',
    };

    useEffect(() => {
        axios
            .all([
                languageExamRequirements.categories(),
                languageExamRequirements.levels(),
                languageExamRequirements.languages(),
                trainingLevelsService.trainingLevels(),
                trainingProgramsService.trainingPrograms(),
            ])
            .then((res) => {
                setOptions((prev) => {
                    return {
                        ...prev,
                        categories: res[0].data,
                        levels: res[1].data,
                        languages: res[2].data,
                    };
                });
                setTrainingLevelOptions(res[3].data);
                setTrainingProgramOptions(res[4].data);
            })
            .catch((err) => console.error('hiba', err))
            .finally(() => setLoading(false));
    }, []);

    function BtnCellRenderer(params: GridRenderCellParams) {
        const neededRoles = accessTree['edit'];
        return (
            neededRoles.some((r: string) => user.roles.includes(r)) && (
                <>
                    {!user.archive ? (
                        <LangExamTableEditButton
                            setTableLoading={refreshing}
                            opener={<TableEditButton tooltip="Szerkesztés" />}
                            objectId={params.row.id}
                        />
                    ) : (
                        <></>
                        /*<TableButton tooltip={'Adatok megtekintése'}>
                            <Visibility />
                        </TableButton>*/
                    )}
                </>
            )
        );
    }

    function ValidatedCellRenderer(params: GridRenderCellParams) {
        switch (params.row.validated) {
            case 'yes':
                return <Chip color="success" label="Igen" />;
            case 'no':
                return <Chip color="error" label="Nem" />;
            case 'waiting':
                return <Chip color="warning" label="Várakozó" />;
        }
    }

    function LanguageCellrenderer(params: GridRenderCellParams) {
        if (params.row.lang == 'Bármilyen') {
            return <>Egyéb</>;
        }
        return <>{params.row.lang}</>;
    }

    const exportContext = {
        initiateExport: langExamReportService.initiateExport,
        checkExport: langExamReportService.checkExport,
        downloadExport: langExamReportService.downloadExport,
        info: info,
        filter: info.filter,
    };

    if (loading) return <Loading noHeight />;

    return (
        <FormikListPage
            header={header}
            refresh={refresh}
            sessionKey={SESSION_KEY}
            service={{
                filter: (form) => {
                    setInfo((prevState) => ({ ...prevState, filter: form })); // Export miatt van itt
                    return langExamReportService.filter(form);
                },
            }}
            autoload
            tableProps={{
                style: {
                    width: '100%',
                },
                autoHeight: true,
            }}
            responseKey="data"
            filter={{
                fields: [
                    {
                        name: 'student',
                        label: 'Hallgató',
                        format: { xs: 12, md: 4, lg: 4 },
                    },
                    {
                        name: 'lang',
                        label: 'Nyelv',
                        format: { xs: 12, md: 4, lg: 4 },
                    },
                    {
                        name: 'type',
                        label: 'Típus',
                        format: { xs: 12, md: 4, lg: 4 },
                        type: 'multiselect',
                        options: options.types,
                    },
                    {
                        name: 'category',
                        label: 'Kategória',
                        format: { xs: 12, md: 4, lg: 4 },
                        type: 'multiselect',
                        options: options.categories,
                    },
                    {
                        name: 'level',
                        label: 'Szint',
                        format: { xs: 12, md: 4, lg: 4 },
                        type: 'multiselect',
                        options: options.levels,
                    },
                    {
                        name: 'createdAtFrom',
                        label: 'Feltöltés ideje (-tól)',
                        type: 'datetime-local',
                        format: { xs: 12, md: 4, lg: 4 },
                    },
                    {
                        name: 'createdAtUntil',
                        label: 'Feltöltés ideje (-ig)',
                        type: 'datetime-local',
                        format: { xs: 12, md: 4, lg: 4 },
                    },
                    {
                        name: 'validated',
                        label: 'Validált',
                        type: 'multiselect',
                        options: validatedOptions,
                        format: { xs: 12, md: 4, lg: 4 },
                    },
                    {
                        name: 'onlyActiveStudents',
                        label: 'Csak beiratkozott hallgatók',
                        type: 'select',
                        options: onlyActiveStudentsOptions,
                        format: { xs: 12, md: 4, lg: 4 },
                    },
                    {
                        name: 'trainingLevel',
                        label: 'Képzési szint',
                        type: 'multiselect',
                        options: trainingLevelOptions,
                        format: { xs: 12, md: 4, lg: 4 },
                    },
                    {
                        name: 'trainingProgram',
                        label: 'Képzési program',
                        type: 'multiselect',
                        options: trainingProgramOptions,
                        format: { xs: 12, md: 4, lg: 4 },
                    },
                ],
            }}
            columns={[
                { headerName: 'Hallgató', field: 'student', disabled: user.archive },
                { headerName: 'Nyelv', field: 'lang', renderCell: LanguageCellrenderer },
                { headerName: 'Típus', field: 'type' },
                { headerName: 'Kategória', field: 'category', disabled: user.archive },
                { headerName: 'Szint', field: 'level' },
                { headerName: 'Feltöltés ideje', field: 'createdAt' },
                { headerName: 'Validált', field: 'validated', renderCell: ValidatedCellRenderer },
                { headerName: 'Műveletek', field: 'operations', renderCell: BtnCellRenderer },
            ].map((c) => ({ minWidth: 150, flex: 1, ...c }))}
            defaultSort={{ student: 'asc' }}
            customButtons={
                !user.archive ? (
                    <Grid item>
                        <ExportButton exportContext={exportContext} />
                    </Grid>
                ) : (
                    <></>
                )
            }
        />
    );
}
