import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { DataGridPro, DataGridProProps } from '@mui/x-data-grid-pro';
import { Dispatch, SetStateAction } from 'react';
import { IInfo } from '../../utils/Interfaces/IInfo';
import { IForm, IMetadata } from '../../utils/Interfaces/interfaces';
import { IStudentRequestData } from '../../utils/Interfaces/IStudentRequestApproval';
import { Paper } from '../Requirements/Paper';
import { columns, partialTableProps } from './utils';

export interface IProcessesRow {
    id: number;
    type: string; //TODO: itt a type-okat megadhatjuk type-ként
    name: string;
    date: string;
    status: string;
    requestData?: IStudentRequestData;
    externalId?: number;
    isCentralData?: boolean;
}

export interface IProcessesTableData {
    data: IProcessesRow[];
    metadata: IMetadata;
}

interface ProcessesTableProps {
    title: string;
    data: IProcessesTableData;
    loading: boolean;
    info: IInfo<IForm>;
    setInfo: Dispatch<SetStateAction<IInfo<IForm>>>;
}

export default function ProcessesTable(props: ProcessesTableProps) {
    const { data, title, loading, info, setInfo } = props;

    const tableProps: DataGridProProps = {
        ...partialTableProps,
        loading: loading,
        onFilterModelChange: ({ items }) =>
            setInfo((prev) => ({
                ...prev,
                filter: items.reduce((prev, curr) => ({ ...prev, [curr.columnField]: curr.value }), {}),
            })),
        onSortModelChange: (model) =>
            setInfo((prev) => ({
                ...prev,
                sort: model[0]?.sort ? { [model[0].field]: model[0].sort } : {},
            })),
        onPageChange: (page) => setInfo((prev) => ({ ...prev, page: page + 1 })),
        onPageSizeChange: (size) => setInfo((prev) => ({ ...prev, perpage: size })),
        columns: columns,
        disableColumnSelector: true,
        rows: data.data || [],
        pageSize: info.perpage,
        rowCount: data.metadata?.filteredCount || 0,
        page: info.page - 1,
    };

    //TODO: info gomb középre rakása

    return (
        <Grid item xs={12}>
            <Paper>
                <Grid item container spacing={3} xs={12}>
                    <Grid item xs={12}>
                        <Typography variant="h6">{title}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <DataGridPro
                            style={{
                                height: data.data?.length > 0 ? 332 : 256,
                                width: '100%',
                            }}
                            autoHeight={false}
                            {...tableProps}
                            disableColumnFilter
                        />
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
}
