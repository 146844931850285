import { endpoints } from '../../utils/endPoints';
import instance from '../../api';
import { IForm } from '../../utils/Interfaces/interfaces';

const trainingProgramRequirementCourseTypesService = {
    list: (levelId?: string, programId?: string, campusId?: string, semesterId?: number, form?: IForm) =>
        instance.get(
            endpoints.masterData.trainingPrograms.requirements.courseType.list
                .replace('{levelId}', levelId ?? '')
                .replace('{programId}', programId ?? '')
                .replace('{campusId}', campusId ?? '') +
                '?semesterId=' +
                semesterId,
            { params: form },
        ),
    courseTypes: (levelId?: string, programId?: string, campusId?: string) =>
        instance.get(
            endpoints.masterData.trainingPrograms.requirements.courseType.courseTypes
                .replace('{levelId}', levelId ?? '')
                .replace('{programId}', programId ?? '')
                .replace('{campusId}', campusId ?? ''),
        ),
    update: (levelId?: string, programId?: string, campusId?: string, semesterId?: number, form?: IForm) =>
        instance.post(
            endpoints.masterData.trainingPrograms.requirements.courseType.update
                .replace('{levelId}', levelId ?? '')
                .replace('{programId}', programId ?? '')
                .replace('{campusId}', campusId ?? '') +
                '?semesterId=' +
                semesterId,
            form,
        ),
    show: (levelId?: string, programId?: string, campusId?: string, semesterId?: number, id?: string) =>
        instance.get(
            endpoints.masterData.trainingPrograms.requirements.courseType.show
                .replace('{levelId}', levelId ?? '')
                .replace('{programId}', programId ?? '')
                .replace('{campusId}', campusId ?? '')
                .replace('{id}', id ?? '') +
                '?semesterId=' +
                semesterId,
        ),
};

export default trainingProgramRequirementCourseTypesService;
