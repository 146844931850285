import { GridColDef, GridColumns, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import instance from '../../../api';
import { endpoints } from '../../../utils/endPoints';
import ArchivedCellRenderer from './ArchivedCellRenderer';
import StatusCellRenderer from './StatusCellRenderer';

const STATUS = new Map([
    [false, 'Nem használt'],
    [true, 'Használatban'],
]);

const calculationTypeOptions = [
    { label: 'Automatikus', value: 'automatic' },
    { label: 'Manuális', value: 'manual' },
];

const completionOptions = [
    { label: 'Automatikus', value: 'automatic' },
    { label: 'Havi teljesítés', value: 'monthly' },
];

const statusOptions = [
    { label: 'Használatban', value: '1' },
    { label: 'Nem használt', value: '0' },
];

const archivedOptions = [
    { label: 'Igen', value: '1' },
    { label: 'Nem', value: '0' },
];

const scholarshipOptions = [
    { label: 'Szakmai', value: 'professional' },
    { label: 'Projekt', value: 'project' },
];

const columns = (Operations: (params: GridRenderCellParams) => JSX.Element, archive?: boolean): GridColumns =>
    [
        { field: 'name', headerName: 'Csoport neve', align: 'left' },
        {
            field: 'scholarshipType',
            headerName: 'Ösztöndíj típusa',
            valueGetter: (params: GridValueGetterParams) =>
                scholarshipOptions.find((option) => option.value === params.value)?.label || params.value,
        },
        {
            field: 'calculationType',
            headerName: 'Kalkuláció típusa',
            valueGetter: (params: GridValueGetterParams) =>
                calculationTypeOptions.find((option) => option.value === params.value)?.label || params.value,
        },
        {
            field: 'completionType',
            headerName: 'Teljesítés típusa',
            valueGetter: (params: GridValueGetterParams) =>
                completionOptions.find((option) => option.value === params.value)?.label || params.value,
        },
        { field: 'used', headerName: 'Státusz', renderCell: StatusCellRenderer },
        { field: 'archived', headerName: 'Archiválva?', renderCell: ArchivedCellRenderer },
        ...(!archive
            ? [{ field: 'operations', headerName: 'Műveletek', sortable: false, renderCell: Operations }]
            : []),
    ].map(
        (column) =>
            ({
                headerAlign: 'center',
                align: 'center',
                flex: 1,
                ...column,
            } as GridColDef),
    );

const breadcrumbs = {
    scholarship: 'Ösztöndíjak',
    groupManagement: 'Csoportok kezelése',
};

const header = (tenant: string | null) => {
    return {
        title: 'Csoportok kezelése',
        breadcrumbs: {},
        project: tenant || 'TAR',
    };
};

const service = (semesterId: string) => ({
    semesters: () => instance.get(endpoints.scholarships.semesters),
    filter: (filter: unknown) => instance.get(endpoints.scholarships.admin.list(semesterId), { params: filter }),
    nameSearch: (term: string) => instance.get(endpoints.scholarships.nameSearch(semesterId) + '?term=' + term),
});

export {
    calculationTypeOptions,
    completionOptions,
    statusOptions,
    archivedOptions,
    columns,
    breadcrumbs,
    header,
    service,
    STATUS,
    scholarshipOptions,
};
