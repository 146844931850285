import Grid from '@mui/material/Grid';
// eslint-disable-next-line regex/invalid-warn
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { AutoloadTable, Loading, NewButton } from '@silinfo/front-end-template';
import { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import EndpointProvider from '../../../../components/EndpointProvider';
import MCCLoading from '../../../../components/MCCLoading';
import coursePrerequisitesService, {
    ICoursePrerequisite,
    ICoursePrerequisiteForm,
} from '../../../../services/masterData/courses/coursePrerequisites/coursePrerequisites';
import { TooltipRenderer } from '../../../../utils/AppConstElements';
import useAccessCheck from '../../../../utils/useAccessCheck';
import AddPrerequisiteButton from './AddPrerequisiteButton';
import DeletePrerequisite from './DeletePrerequisite';
import { tableColumns } from './fields';
import useColumnOrder from '../../../../utils/useColumnOrder';

export default function Prerequisites(props: { closed: boolean; readOnly?: boolean }) {
    const { courseId } = useParams() as { courseId: string };
    const id: number = parseInt(courseId);
    const [loading, setLoading] = useState(false);
    const [tableLoading, setTableLoading] = useState(false);
    const hasAccessToEdit = useAccessCheck(['course_manamagement_course_edit_base']);
    const { closed, readOnly } = props;
    const [searchParams] = useSearchParams();
    const courseTenant = searchParams.get('courseTenant');

    const OperationCellRenderer = ({ row }: GridRenderCellParams<ICoursePrerequisite, ICoursePrerequisite>) => {
        return (
            <Grid container spacing={1} flexDirection={'row'}>
                {!closed && !readOnly && (
                    <Grid item xs={6}>
                        <DeletePrerequisite
                            courseId={id}
                            prerequisiteToDelete={row}
                            setLoading={setLoading}
                            courseTenant={courseTenant}
                        />
                    </Grid>
                )}
            </Grid>
        );
    };
    const courseNameCellRenderer = ({ row }: GridRenderCellParams<ICoursePrerequisite, ICoursePrerequisite>) => {
        return TooltipRenderer(row.courseName);
    };

    const [columnDefs] = useState(
        !readOnly
            ? tableColumns(OperationCellRenderer, courseNameCellRenderer, hasAccessToEdit)
            : tableColumns(OperationCellRenderer, courseNameCellRenderer, hasAccessToEdit).slice(0, -1),
    );
    const { handleColumnOrderChange, getOrderedColumns } = useColumnOrder(columnDefs, 'course_list_prerequisites');

    if (loading) {
        return <MCCLoading />;
    }

    return (
        <Grid container spacing={2}>
            <Grid item container xs={12} justifyContent="flex-end">
                {!closed && !readOnly && (
                    <EndpointProvider endpoints={['course_manamagement_course_edit_base']}>
                        <Grid item container justifyContent="flex-end" xs={12}>
                            <Grid item>
                                <AddPrerequisiteButton
                                    title="Előfeltétel hozzáadása"
                                    submitBtnTitle="Hozzáadás"
                                    setTableLoading={setTableLoading}
                                    opener={<NewButton>Előfeltétel hozzáadása</NewButton>}
                                    courseTenant={courseTenant}
                                />
                            </Grid>
                        </Grid>
                    </EndpointProvider>
                )}
            </Grid>
            <Grid item xs={12}>
                {tableLoading ? (
                    <Loading noHeight />
                ) : (
                    <AutoloadTable
                        columns={getOrderedColumns()}
                        filter={(form) =>
                            coursePrerequisitesService.filterByCourse(form as ICoursePrerequisiteForm, id, courseTenant)
                        }
                        responseKey="data"
                        onColumnOrderChange={handleColumnOrderChange}
                    />
                )}
            </Grid>
        </Grid>
    );
}
