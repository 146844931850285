import Grid from '@mui/material/Grid';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { FormikListPage, Link, NewButton, TableDeleteButton, TableEditButton } from '@silinfo/front-end-template';
import axios from 'axios';
import i18n from 'i18next';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import MCCLoading from '../../../../../components/MCCLoading';
import languageExamRequirements from '../../../../../services/masterData/languageExamRequirements';
import trainingLevelService from '../../../../../services/masterData/trainingLevels';
import { RootState } from '../../../../../store';
import { create } from '../../../../../store/notification';
import { Option } from '../../../../../utils/AppConst';
import { TooltipRenderer } from '../../../../../utils/AppConstElements';
import { clientEndPoints } from '../../../../../utils/clientEndPoints';
import { ILanguageExamRequirement, filterFields } from './fields';
import { useTenant } from '../../../../../components/TenantContext';

export default function LanguageExamRequirements() {
    const { user } = useSelector((state: RootState) => state.auth);
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState<{ categories: Option[]; levels: Option[]; languages: Option[] }>({
        categories: [],
        levels: [],
        languages: [],
    });
    const { trainingLevelId } = useParams() as { trainingLevelId: string };
    const [levelName, setLevelName] = useState('');
    const dispatch = useDispatch();
    const tenant = useTenant();

    const header = {
        project: tenant || 'TAR',
        title: 'Nyelvvizsga követelmények',
        breadcrumbs: {
            masterData: 'Törzsadatok',
            [clientEndPoints.md_general]: 'Általános',
            [clientEndPoints.md_training_levels_list]: levelName + ' szint',
            languageExamRequirements: 'Nyelvvizsga követelmények',
        },
    };

    useEffect(() => {
        setLoading(true);
        axios
            .all([
                languageExamRequirements.categories(),
                languageExamRequirements.levels(),
                languageExamRequirements.languages(),
            ])
            .then((res) => {
                setOptions((prev) => {
                    return {
                        ...prev,
                        categories: res[0].data,
                        levels: res[1].data,
                        languages: res[2].data,
                    };
                });
            })
            .catch(() =>
                dispatch(
                    create({
                        type: 'error',
                        message: 'Sikertelen betöltés',
                    }),
                ),
            )
            .finally(() => setLoading(false));

        trainingLevelService.trainingLevels().then((response) => {
            setLevelName(response.data.find((element: Option) => element.value == trainingLevelId).label);
        });
    }, [trainingLevelId, dispatch]);

    const filter = {
        fields: filterFields(options.levels, options.categories, options.languages),
    };

    const columns: GridColDef[] = [
        {
            headerName: 'Nyelv',
            field: 'language',
            renderCell: (params: GridRenderCellParams) =>
                TooltipRenderer(
                    i18n.t(
                        'master_data.training_levels.language_exam_requirements.languages.' + params.row.language,
                    ) as string,
                ),
            minWidth: 150,
        },
        { headerName: 'Szint', field: 'level' },
        {
            headerName: 'Kategória',
            field: 'category',
            renderCell: (params: GridRenderCellParams) =>
                TooltipRenderer(
                    i18n.t(
                        'master_data.training_levels.language_exam_requirements.category.' + params.row.category,
                    ) as string,
                ),
        },
        {
            headerName: 'Türelmi idő',
            field: 'gracePeriod',
            renderCell: (params: GridRenderCellParams) =>
                TooltipRenderer(
                    i18n.t(
                        'master_data.training_levels.language_exam_requirements.grace_period.' + params.row.gracePeriod,
                    ) as string,
                ),
        },
        {
            headerName: 'Műveletek',
            field: 'operations',
            renderCell: (params: GridRenderCellParams<string, ILanguageExamRequirement>) =>
                !user.archive ? (
                    <Grid container spacing={1}>
                        <Grid item>
                            <Link to={params.row.id + '/edit'}>
                                <TableEditButton />
                            </Link>
                        </Grid>
                        <Grid item>
                            <TableDeleteButton
                                deleteAction={() => {
                                    setLoading(true);
                                    languageExamRequirements
                                        .delete(params.id)
                                        .then(() => {
                                            dispatch(create({ type: 'success', message: 'Sikeres törlés' }));
                                        })
                                        .catch(() => dispatch(create({ type: 'error', message: 'Sikertelen törlés' })))
                                        .finally(() => setLoading(false));
                                }}
                                confirmationElement={
                                    i18n.t(
                                        'master_data.training_levels.language_exam_requirements.languages.' +
                                            params.row.language,
                                    ) +
                                    ' (' +
                                    params.row.level +
                                    ' ' +
                                    i18n.t(
                                        'master_data.training_levels.language_exam_requirements.category.' +
                                            params.row.category,
                                    ) +
                                    ')'
                                }
                            />
                        </Grid>
                    </Grid>
                ) : (
                    <></>
                ),
            sortable: false,
        },
    ].map((col) => ({ minWidth: 100, flex: 1, ...col }));

    /*    function handleCheck() {
        languageExamRequirements.check().then(() => alert('Sikeres ellenőrzés!'));
    }
    function handleCheckFine() {
        languageExamRequirements.checkFine().then(() => alert('Sikeres ellenőrzés!'));
    }*/

    if (loading) return <MCCLoading />;

    return (
        <FormikListPage
            autoload
            header={header}
            service={{ filter: (form) => languageExamRequirements.filterByTrainingLevel(form, trainingLevelId) }}
            columns={columns}
            defaultSort={{ language: 'asc' }}
            customButtons={
                !user.archive ? (
                    <Grid item container xs={12} justifyContent="flex-end" spacing={1}>
                        <Grid item>
                            <Link to="new">
                                <NewButton>Új nyelvvizsga követelmény hozzáadása</NewButton>
                            </Link>
                        </Grid>
                        {/*  <Grid item>
                        <Button color="success" variant="outlined" startIcon={<Check />} onClick={handleCheck}>
                            Követelmények ellenőrzése (teszt)
                        </Button>
                    </Grid>

                    <Grid item>
                        <Button color="success" variant="outlined" startIcon={<Check />} onClick={handleCheckFine}>
                            Követelmények ellenőrzése és büntetés kiszabása(teszt)
                        </Button>
                    </Grid>*/}
                    </Grid>
                ) : (
                    <></>
                )
            }
            filter={filter}
            tableProps={{
                style: {
                    width: '100%',
                },
                autoHeight: true,
            }}
        />
    );
}
