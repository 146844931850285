import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { DataGridPro, GridColDef, GridRowModel } from '@mui/x-data-grid-pro';
import { translation } from '@silinfo/front-end-template';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { theme } from '../../../utils/theme';
import InfoIcon from '@mui/icons-material/Info';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';

export default function Semesters() {
    const { profile } = useSelector((state: RootState) => state.studentProfile);
    const columns: GridColDef[] = [
        { headerName: 'Szemeszter', field: 'semester' },
        { headerName: 'Képzési szint', field: 'trainingLevel' },
        { headerName: 'Képzési program', field: 'trainingProgram' },
        { headerName: 'Szemeszter státusz', field: 'semesterStatus' },
    ];

    return (
        <Paper elevation={1}>
            <Grid container style={{ backgroundColor: '#a1b1c4' }} padding={2}>
                <Typography padding="4px 5px 4px 0px" color="white" variant="h5">
                    Felvett szemeszterek
                </Typography>
                <Typography color="yellow" variant="caption" sx={{ alignItems: 'center', display: 'flex' }}>
                    <Tooltip
                        title="Az alábbi táblázat a beiratkozáskor megadott adatokat tükrözi, amennyiben ezekben a szemeszter
                    közben változás történt, úgy a fenti adatok eltérőek lehetnek!"
                    >
                        <InfoIcon />
                    </Tooltip>
                </Typography>
            </Grid>
            <DataGridPro
                density="comfortable"
                columns={columns.map((column) => ({
                    ...column,
                    flex: 1,
                    disableColumnMenu: true,
                    sortable: false,
                    minWidth: 150,
                }))}
                rows={profile.enrolledSemesters as GridRowModel[]}
                disableSelectionOnClick
                style={{
                    width: '100%',
                }}
                autoHeight={true}
                sx={{
                    backgroundColor: theme.palette.common.white,
                }}
                localeText={translation}
                hideFooter
            />
        </Paper>
    );
}
