/* eslint-disable @typescript-eslint/no-empty-function */
import { Option } from '@silinfo/front-end-template';
import { createContext, PropsWithChildren, useContext, useState } from 'react';

interface EvaluableContextType {
    isCourseEvaluable: boolean;
    setIsCourseEvaluable: (value: boolean) => void;
    evaluationOptions: Option[];
    setEvaluationOptions: (value: Option[]) => void;
}

export const EvaluableContext = createContext<EvaluableContextType>({
    isCourseEvaluable: false,
    setIsCourseEvaluable: () => {},
    evaluationOptions: [],
    setEvaluationOptions: () => {},
});

export const EvaluableContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [isCourseEvaluable, setIsCourseEvaluable] = useState<boolean>(false);
    const [evaluationOptions, setEvaluationOptions] = useState<Option[]>([]);

    const contextValue: EvaluableContextType = {
        isCourseEvaluable,
        setIsCourseEvaluable,
        evaluationOptions,
        setEvaluationOptions,
    };

    return <EvaluableContext.Provider value={contextValue}>{children}</EvaluableContext.Provider>;
};

export const useEvaluableContext = () => {
    const context = useContext(EvaluableContext);

    if (context === undefined) {
        throw new Error('useEvaluableContext must be used within a EvaluableContextProvider');
    }

    return context;
};

export default EvaluableContext;
