import VisibilityIcon from '@mui/icons-material/Visibility';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import {
    DataGridProProps,
    GridColDef,
    GridFeatureMode,
    GridRowClassNameParams,
    GridValueGetterParams,
} from '@mui/x-data-grid-pro';
import { Input, Link } from '@silinfo/front-end-template';
import { AxiosResponse } from 'axios';
import instance from '../../../api';
import { Option } from '../../../utils/AppConst';
import { IForm } from '../../../utils/Interfaces/interfaces';
import { clientEndPoints } from '../../../utils/clientEndPoints';
import { endpoints } from '../../../utils/endPoints';
import { theme } from '../../../utils/theme';
import { semesterStatusOptions } from '../../MasterData/General/Students/Form/options';
import {
    CompletedCellRenderer,
    CompletionCell,
    EnrolledCoursesCellRenderer,
    LanguageExamRequirementsCellRenderer,
    MandatoryCoursesCellRenderer,
    StatusCellRenderer,
} from '../../MasterData/General/Students/StudentView/CollapsibleTable/CellRenderers';
import { ICourseCompletion } from '../../MasterData/General/Students/StudentView/types';
import CourseCompletionsStyle from './CourseCompletions.module.scss';
import { IStudentCompletionData, RenderCellParams } from './types';
import PDFDownloader from '../../../components/PDFDownloader';
import ComponentToPrint from '../../MasterData/General/Students/StudentView/CollapsibleTable/ComponentToPrint';
import EndpointProvider from '../../../components/EndpointProvider';
import courseCompletionsService from '../../../services/administration/courseCompletions';
import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import CourseTypeRequirements from '../../../studentPages/Requirements/CourseTypeRequirements';
import { SxProps, Theme } from '@mui/system/';

export const breadcrumbs = {
    administration: 'Adminisztráció',
    courseCompletions: 'Képzésteljesítések',
};

export const header = (tenant: string | null) => {
    return {
        title: 'Képzésteljesítések',
        breadcrumbs: {},
        project: tenant || 'TAR',
    };
};

const baseCompletionOptions: Option[] = [
    {
        label: 'Teljesített',
        value: 'success',
    },
    {
        label: 'Nem teljesített',
        value: 'failed',
    },
];

const completionOptions: Option[] = [
    ...baseCompletionOptions,
    {
        label: 'Nincs',
        value: 'none',
    },
];

const completedOptions: Option[] = [
    {
        label: 'Követelményt teljesítők',
        value: 'yes',
    },
    {
        label: 'Minimumot el nem érők',
        value: 'no',
    },
];

const statusOptions: Option[] = [
    ...baseCompletionOptions,
    {
        label: 'Folyamatban',
        value: 'in_progress',
    },
];

const studentsOptions: Option[] = [
    {
        label: 'Beiratkozott',
        value: 'active',
    },
    {
        label: 'Inaktív',
        value: 'inactive',
    },
];

export const columns = (trainingProgram: string | number, archive?: boolean): GridColDef<IStudentCompletionData>[] =>
    [
        {
            field: 'completed',
            headerName: 'Teljesített',
            renderCell: (params: RenderCellParams) => <CompletedCellRenderer {...params.row} />,
        },
        {
            field: 'enrolledCourses',
            headerName: 'Felvett kurzusok',
            renderCell: (params: RenderCellParams) => <EnrolledCoursesCellRenderer {...params.row} />,
        },
        {
            field: 'minToBeCompleted',
            headerName: 'Minimum teljesítendő',
        },
        {
            field: 'mandatoryCourses',
            headerName: 'Kötelező kurzusok',
            renderCell: (params: RenderCellParams) => (
                <MandatoryCoursesCellRenderer
                    row={params.row}
                    url={endpoints.masterData.students.trainingProgramCompletion.requiredCourseInfo}
                />
            ),
        },
        {
            field: 'electiveCourses',
            headerName: 'Köt. vál. kurzusok',
            renderCell: (params: RenderCellParams<ICourseCompletion>) =>
                params.value ? <CompletionCell {...params.value} /> : '-',
        },
        {
            field: 'coCourses',
            headerName: 'Társképzésből teljesítendő',
            renderCell: (params: RenderCellParams<ICourseCompletion>) =>
                params.value ? <CompletionCell {...params.value} /> : '-',
        },
        {
            field: 'languageExamRequirements',
            headerName: 'Nyelvvizsga követelmények',
            renderCell: (params: RenderCellParams) => (
                <LanguageExamRequirementsCellRenderer
                    row={params.row}
                    url={endpoints.masterData.students.trainingProgramCompletion.langInfo}
                />
            ),
        },
        {
            field: 'status',
            headerName: 'Státusz',
            renderCell: (params: RenderCellParams) => <StatusCellRenderer {...params.row} />,
        },
        {
            field: 'actions',
            headerName: 'Műveletek',
            renderCell: (params: RenderCellParams) =>
                params.row.isStudent ? (
                    <>
                        <InfoDialog
                            url={endpoints.administration.courseCompletions.courseTypeInfo(
                                params.row.id,
                                trainingProgram,
                            )}
                            sx={{
                                border: '1px solid ' + theme.palette.primary.main,
                                '&:hover': { backgroundColor: theme.palette.action.hover },
                                padding: '8px',
                                marginRight: '5px',
                            }}
                        />
                        {!archive && (
                            <EndpointProvider endpoints={['md_general']}>
                                <Link
                                    to={
                                        clientEndPoints.md_general_students_edit_detailed.replace(
                                            ':id',
                                            '' + params.id,
                                        ) +
                                        '?tab=1&trainingProgram=' +
                                        trainingProgram
                                    }
                                >
                                    <Tooltip title="Hallgatói részletes adatok">
                                        <IconButton
                                            color="primary"
                                            sx={{
                                                border: '1px solid ' + theme.palette.primary.main,
                                                '&:hover': { backgroundColor: theme.palette.action.hover },
                                            }}
                                        >
                                            <VisibilityIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Link>
                            </EndpointProvider>
                        )}
                    </>
                ) : (
                    <>
                        <PDFDownloader
                            url={endpoints.masterData.students.trainingProgramCompletion.pdf(
                                trainingProgram,
                                params.row.completionId,
                            )}
                            ComponentToPrint={ComponentToPrint}
                        />
                    </>
                ),
        },
    ].map((column) => ({
        flex: 1,
        minWidth: 150,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        ...column,
    }));

export const filterFields = (programId: string, campuses: Option[]): Input[] => [
    {
        name: 'student',
        label: 'Hallgató',
        type: 'autocomplete',
        multiple: true,
        getter: (term: string) => courseCompletionsService.studentSearch(programId, term),
        createOptions: (data: Option[]) => data.map((option: Option) => ({ value: option.value, label: option.label })),
        autocompleteProps: {
            ChipProps: {
                sx: {
                    backgroundColor: theme.palette.secondary.main,
                    color: theme.palette.secondary.contrastText,
                    '& .MuiChip-deleteIcon': {
                        color: theme.palette.primary.main,
                    },
                },
            },
        },
    },
    {
        name: 'completed',
        label: 'Teljesített pontok',
        type: 'multiselect',
        options: completedOptions,
    },
    {
        name: 'mandatoryCourses',
        label: 'Kötelező kurzusok',
        type: 'multiselect',
        options: completionOptions,
    },
    {
        name: 'languageExamRequirements',
        label: 'Nyelvvizsga követelmények',
        type: 'multiselect',
        options: completionOptions,
    },
    {
        name: 'status',
        label: 'Státusz',
        type: 'multiselect',
        options: statusOptions,
    },
    {
        name: 'students',
        label: 'Beiratkozott hallgatók',
        type: 'multiselect',
        options: studentsOptions,
    },
    {
        name: 'semesterStatus',
        label: 'Hallgatói szemeszter státusz',
        type: 'multiselect',
        options: semesterStatusOptions,
    },
    {
        name: 'campus',
        label: 'Campus',
        type: 'multiselect',
        options: campuses,
    },
];

export default function InfoDialog({ url, sx }: { url: string; sx?: SxProps<Theme> | undefined }) {
    const [open, setOpen] = useState(false);

    return (
        <>
            <Tooltip title="Kötelezően választható kurzusok">
                <IconButton
                    color="info"
                    sx={sx}
                    onClick={() => {
                        setOpen(true);
                    }}
                >
                    <InfoIcon />
                </IconButton>
            </Tooltip>
            <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
                <DialogTitle>Kötelezően választható kurzusok</DialogTitle>
                <DialogContent style={{ paddingTop: '16px' }}>
                    <CourseTypeRequirements fullWidth url={url} selectedProgram={null} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} variant="outlined">
                        Bezárás
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

const rowsConverter = (rows: IStudentCompletionData[]): IStudentCompletionData[] => {
    const result: IStudentCompletionData[] = [];

    rows.forEach((row) => {
        result.push({ ...row, isStudent: true });
        row.semesters.forEach((semester) => {
            result.push({
                ...semester,
                id: row.id + '-' + semester.id,
                semesters: [],
                isStudent: false,
            });
        });
    });

    return result;
};

export const service = (id: string | number) => ({
    filter: async (form: IForm): Promise<AxiosResponse> => {
        const convertedForm: IForm = {
            ...form,
            ...(form['order[__tree_data_group__]'] ? { ['order[name]']: form['order[__tree_data_group__]'] } : {}),
        };
        delete convertedForm['order[__tree_data_group__]'];
        const { data, ...rest } = await instance.get(endpoints.administration.courseCompletions.main(id), {
            params: convertedForm,
        });

        return {
            ...rest,
            data: {
                ...data,
                data: rowsConverter(data.data),
            },
        };
    },
});

const getTreeDataPath: DataGridProProps['getTreeDataPath'] = (row) => ('' + row.id).split('-');

const autoload = true as const;

export const tableProps = (trainingProgram: string, campuses: Option[], archive?: boolean, tenant?: string) => ({
    autoload,
    header: header(tenant || 'TAR'),
    service: service(trainingProgram),
    columns: columns(trainingProgram, archive),
    filter: {
        initialValues: {
            students: ['active'],
        },
        fields: filterFields(trainingProgram, campuses),
    },
    refresh: trainingProgram,
    defaultSort: { __tree_data_group__: 'asc' as const },
    responseKey: 'data',
    tableProps: {
        treeData: true,
        getTreeDataPath,
        groupingColDef: {
            sortable: true,
            headerName: 'Hallgató',
            valueGetter: (params: GridValueGetterParams) => params.row.name,
            hideDescendantCount: true,
        },
        columnBuffer: columns(0).length,
        filterMode: 'client' as GridFeatureMode,
        getRowClassName: (params: GridRowClassNameParams) =>
            (params.row.isStudent && CourseCompletionsStyle.semesterRow) ||
            (params.row.disabled && CourseCompletionsStyle.disabledRow),
        disableSelectionOnClick: true,
        initialState: {
            pinnedColumns: {
                right: ['operations'],
            },
            sorting: {
                sortModel: [{ field: '__tree_data_group__', sort: 'asc' as const }],
            },
        },
        style: {
            width: '100%',
        },
        autoHeight: true,
    },
});
