import Grid from '@mui/material/Grid';
import { FormikListPage, Input, Option } from '@silinfo/front-end-template';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import MCCLoading from '../../../components/MCCLoading';
import { IForm } from '../../../utils/Interfaces/interfaces';
import { RootState } from '../../../store';
import UniqueFeeDialog from './UniqueFeeDialog';
import { SESSION_KEY, TabContentProps, columns, header } from './utils';
import { filterFields } from './fields';
import { AxiosResponse } from 'axios';
import administrationFeesService from '../../../services/masterData/finances/administrationFees/administrationFees';
import api from '../../../api';
import { endpoints } from '../../../utils/endPoints';
import { useTenant } from '../../../components/TenantContext';
import instance from '../../../api';

export default function TabContent({ view }: TabContentProps) {
    const { user } = useSelector((state: RootState) => state.auth);
    const [loading, setLoading] = useState(false);
    const [refresh, setReFresh] = useState<boolean>(false);
    const [defaultSemester, setDefaultSemester] = useState<null | string>(null);
    const [currency, setCurrency] = useState<string>('');
    const [fields, setFields] = useState<Input[]>(filterFields(view));
    const tenant = useTenant();
    const headerWithTenant = header(tenant || 'TAR');
    useEffect(() => {
        api.get(endpoints.currentTenantAll)
            .then((response) => {
                setCurrency(response.data.currencyTrans);
            })
            .catch((error) => {
                console.error('Error fetching current tenant currency:', error);
            });
    }, []);

    useEffect(() => {
        setLoading(true);
        administrationFeesService.semesters().then((response: AxiosResponse) => {
            const field = fields.find((f) => f.name === 'semester');
            if (field?.type === 'multiselect') {
                field.options = response.data.map((value: Option & { default: boolean }) => {
                    if (value.default) {
                        setDefaultSemester(value.value);
                    }

                    return {
                        value: value.value,
                        label: value.label,
                    };
                });

                setFields(fields.map((f) => (f.name === 'semester' ? field : f)));
            }
        });

        instance
            .get(endpoints.tenantList)
            .then((response: AxiosResponse) => {
                const field = fields.find((f) => f.name === 'tenant');
                if (field?.type === 'multiselect') {
                    field.options = response.data.map((value: Option) => ({
                        value: value.value,
                        label: value.label,
                    }));

                    setFields(fields.map((f) => (f.name === 'tenant' ? field : f)));
                }
            })
            .finally(() => setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const filter = {
        fields,
        initialValues: {
            semester: defaultSemester ? [defaultSemester] : [],
            status: [
                'wait_to_un_admin_approval',
                'wait_to_leader_approval',
                'invoice_during_issue',
                'waiting_for_payment',
                'paid',
                'waiting_for_payment_out',
                'paid_for',
            ],
        },
    };

    const getData = async (form: IForm) => {
        const result = await administrationFeesService[view === 'base' ? 'filter' : 'filterGuest'](form);
        return {
            ...result,
            data: {
                ...result.data,
                'hydra:member': result.data.data,
            },
        };
    };

    // const deleteableFee = (feeId: string, status: string) => {
    //     let decider = true;
    //     processes.forEach((process: { id: string; financeAdministrationFeeVal: IAdministrationFeeValType }) => {
    //         if (process.id == feeId) {
    //             const typeShort = process?.financeAdministrationFeeVal?.financeAdministrationFee?.typeShort;
    //             if (['KOT', 'NYD', 'KAU', 'SZK'].includes(typeShort)) {
    //                 decider = false;
    //             }
    //             if (
    //                 [
    //                     'deleted_wait_to_un_admin_approval',
    //                     'deleted_wait_to_leader_approval',
    //                     'deleted_invoice_during_issue',
    //                     'deleted_waiting_for_payment',
    //                     'deleted_waiting_for_payment_out',
    //                     'paid',
    //                     'paid_for',
    //                 ].includes(status)
    //             ) {
    //                 decider = false;
    //             }
    //             return decider;
    //         }
    //     });
    //     return decider;
    // };
    // const dispatch = useDispatch();

    if (loading) return <MCCLoading />;

    return (
        <FormikListPage
            autoload
            header={{ ...headerWithTenant, breadcrumbs: {} }}
            service={{ filter: getData }}
            columns={columns(view).map((elem) => ({ flex: 1, minWidth: 150, ...elem }))}
            defaultSort={{ createdAt: 'desc' }}
            newLabel={!user.archive && view === 'base' ? 'Adminisztrációs díj hozzárendelése hallgatóhoz' : ''}
            refresh={refresh}
            sessionKey={SESSION_KEY}
            customButtons={
                <>
                    {!user.archive && view === 'base' ? (
                        <Grid item>
                            <UniqueFeeDialog setReFresh={setReFresh} currency={currency} />
                        </Grid>
                    ) : (
                        <></>
                    )}
                </>
            }
            filter={filter}
            tableProps={{
                columnBuffer: columns(view).length,
                initialState: {
                    pinnedColumns: {
                        right: ['status'],
                    },
                },
                style: {
                    width: '100%',
                },
                autoHeight: true,
            }}
        />
    );
}
